<template>
  <div class="banner hybrid-meetings">
    <NavigationBar />
    <div class="banner-content">
      <router-link to="/"><img class="ease-logo" src="@/assets/ease-hospitality-logo.svg" alt="Ease Hospitality"/></router-link>
      <h1 data-aos="fade-in">
        Experience Digital with Ease
      </h1>
      <p>Flexible & customizable suite of services available to meet your evolving Hybrid & Virtual workspace demands.</p>
    </div>
    <div class="scroll-to-bottom-container">
      <img src="@/assets/down-arrow.svg" />
    </div>
  </div>
</template>

<style lang="scss">
@import '@/styles/banner.scss';
.banner.meetings-and-events-spaces{
  .banner-content{
    h1{
      font-weight: normal !important;
    }
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script>
import NavigationBar from '@/components/NavigationBar.vue'
export default {
  name: 'MeetingsBanner',
  components: {
    NavigationBar,
  }
}
</script>
