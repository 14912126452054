<template>
  <div class="locations" v-resize="onResize">
    <NavigationBar />
<!--     <HoursBanner /> -->
    <HoursBody />
  </div>
</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue'
/* eslint-disable vue/no-unused-components */
// import HoursBanner from "@/components/hours/Banner.vue";
import HoursBody from "@/components/hours/Body.vue";

export default {
  name: 'Hours',
  components: {
    // HoursBanner,
    NavigationBar,
    HoursBody,
  }
}
</script>
