export class Endpoints {
    static ContactForm = this.url('forms/contact');
    static RSVPForm = this.url('forms/rsvp');
    static PostList(post_type) {
        return this.url('public-post/' + post_type);
    }
    static EventList = this.url('public/event');
    static BlogPage(blog_id) {
        return this.url('public-post/blog/' + blog_id)
    }
    static EventPage(event_id) {
        return this.url('public-post/event/' + event_id)
    }

    static url(path) {
        return path
    }
}