<template>
  <div id="news-press">
<!--     <div class="row page-buttons">
      <div class="col-4 post-type-button">
        <a href="/press" :class="{'active-page': activePage == 'press'}" id="press" @click="pageButtonClicked">Press</a>
      </div>
      <div class="col-4 post-type-button">
        <a href="/blog" :class="{'active-page': activePage == 'blog'}" id="blog" @click="pageButtonClicked">Journal</a>
      </div>
      <div class="col-4 post-type-button">
        <a href="/events" :class="{'active-page': activePage == 'events'}" id="events" @click="pageButtonClicked">Events</a>
      </div>
    </div> -->
    <!-- News Container -->
    <BlogList v-if="activePage == 'blog'" />
    <PressList v-if="activePage == 'press'" />
    <EventList v-if="activePage == 'events'" />

    <!-- Separator -->
    <img class="gradient-separator" src="@/assets/about-us/gradient-separator.png" alt="Divider"/>

    <!-- We'd Love To -->
    <div class="text-full-width text-centered contact-section" data-aos="fade-in">
      <div class="Aligner">
        <div class="Aligner-item Aligner-item--top"></div>
        <div class="Aligner-item">
          <h1>We'd Love To<br>
            Hear From You</h1>
          <p>Please let us know how we can be of assistance.</p>
          <router-link to="/contact">Contact Us</router-link>
        </div>
        <div class="Aligner-item Aligner-item--bottom"></div>
      </div>
    </div>
    <loading :active.sync="isLoading"
             :is-full-page="true"></loading>
  </div>
</template>

<style lang="scss">
@import '../../styles/page-body.scss';

#news-press{
  .page-buttons {
    text-align: center;
    margin: 0 25% 100px 25%;
    a {
      outline: none;
      background: transparent;
      border: none;
      font-size: 38px;
      color: #2A4735;
      &.active-page {
        border-bottom: #EEC6BB 1px solid;
      }
    }
  }

  .news-item{
    .image-content{
      .image-wrapper{
        background: #C4C4C4;
        /* height: 500px; */
      }
      img{
        height: 500px;
        &[src=""] {
          visibility: hidden;
        }
      }
    }

    .text-content{
      .news-footer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        p{
          font-size: 18px;
          line-height: 26px;
        }
        a{
          margin-bottom: 16px;
          font-weight: bold;
          font-size: 18px;
          letter-spacing: 0.2em;
          text-transform: uppercase;
          color: $palm-green;
          border-bottom: 2px solid #EEC6BB;
        }
      }
    }
  }

  /** Mobile */
  @media only screen and (max-width: 1023px) {
    .text-content{
      .news-footer{
        display: block !important;
        margin: 0 auto;
        p{
          display: block !important;
        }
        a{
          display: block !important;
          text-align: center;
          margin: 0 25%;
        }
      }
    }

    .page-buttons {
      margin: 0 4% 50px 4%;
      .post-type-button {
        a {
          font-size: 28px;
        }
      }
    }

    .news-container {
      .news-item {
        margin-bottom: 35px;
      }
    }

  }
}
</style>

<script>
import 'vue-loading-overlay/dist/vue-loading.css';
import Loading from 'vue-loading-overlay';
import BlogList from "@/components/blog/BlogList.vue";
import PressList from "@/components/press/PressList.vue";
import EventList from "@/components/events/EventList.vue";
export default {
  name: 'Blog',
  components: {
    Loading,
    BlogList,
    PressList,
    EventList
  },
  data: function(){
    return {
      isLoading: false,
      activePage: 'press',
      page: 1,
      posts: [
      ],
    }
  },
  methods: {
    pageButtonClicked(e) {
      e.preventDefault()
      history.pushState(
          {},
          null,
          e.target.id
      )
      this.activePage = e.target.id
    },
  },
  mounted: function(){
    this.activePage = this.$route.path.substring(1)
  },
}
</script>