<template>
    <div v-if="!this.isLoading" class="news-card-list">
        <NewsCard :viewModel="this.items[0]" />
        <NewsCard :viewModel="this.items[1]" />
        <NewsCard :viewModel="this.items[2]" />
    </div>
</template>

<script>
import {API} from "@/API";
import {PostType} from "@/enums/PostType";
import {NewsCardViewModel} from "@/models/NewsCardViewModel";
import NewsCard from "@/components/home/NewsCard";

export default {
    data: function() {
        return {
            items: [],
            isLoading: true,
        };
    },
    methods: {
        fetchPress() {
            const $this = this;
            API.getPosts(PostType.press)
                .then(function (response){
                    const result = response.data
                    $this.items = result.data.map(function (post){
                        return new NewsCardViewModel(post);
                    });
                    $this.isLoading = false;
                }
            );
        },
    },
    mounted: function() {
        this.fetchPress();
    },
    components: {
        NewsCard,
    },
}
</script>

<style lang="scss" scoped>
    .news-card-list {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    // NewsCardList becomes a column in smaller screens
    @media only screen and (max-width: 1182px) {
        .news-card-list {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
</style>