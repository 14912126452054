<template>
  <div class="banner home">
    <NavigationBar />
    <div class="banner-content">
      <img class="ease-logo" src="@/assets/ease-hospitality-logo.svg" alt="Ease Hospitality"/>
      <h1 data-aos="fade-in">Where People, Technology and Wellness Meet</h1>
      <p>A curated experience and full-service destination to host the modern meeting.</p>
    </div>
    <!-- <div class="banner-features">
      <div class="features-wrapper" data-aos="fade-up" data-aos-delay="400">
        <div class="feature-item" data-aos="fade-in" data-aos-delay="800">
          <router-link to="about-us#meetings-and-events">
            <img src="@/assets/home/banner-icons/meetings-events.svg" alt="Hybrid Meetings & Events"/>
            <p>Meetings &amp; Events</p>
          </router-link>
        </div>
        <div class="feature-item" data-aos="fade-in" data-aos-delay="500">
          <router-link to="about-us#full-service-amenities-spaces">
            <img src="@/assets/home/banner-icons/tenant-lounge.svg" alt="Tenant Lounge"/>
            <p>Tenant Lounge</p>
          </router-link>
        </div>
        <div class="feature-item" data-aos="fade-in" data-aos-delay="600">
          <router-link to="about-us#seamless-solutions">
            <img src="@/assets/home/banner-icons/food-beverage.svg" alt="Food & Beverage"/>
            <p>Food &amp; Beverage</p>
          </router-link>
        </div>
        <div class="feature-item" data-aos="fade-in" data-aos-delay="700">
          <router-link to="about-us#workplace-solutions">
            <img src="@/assets/home/banner-icons/workspace.svg" alt="Workspace"/>
            <p>Digital</p>
          </router-link>
        </div>
        <div class="feature-item" data-aos="fade-in" data-aos-delay="900">
          <router-link to="about-us#seamless-solutions">
            <img src="@/assets/home/banner-icons/wellness.svg" alt="Wellness"/>
            <p>Wellness</p>
          </router-link>
        </div>
      </div>
    </div> -->
    <div class="banner-features">
      <router-link to="/contact" class="txt-link left-link">
        <button class="location-button">Book Now</button>
      </router-link>
      <router-link to="/locations" class="txt-link left-link">
        <button class="location-button">Our Locations</button>
      </router-link>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/styles/banner.scss';

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script>
import NavigationBar from '@/components/NavigationBar.vue'
export default {
  name: 'HomeBanner',
  components: {
    NavigationBar,
  }
}
</script>
