<template>
  <div class="press-page">
    <CareersBanner />
    <Body />
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import CareersBanner from "@/components/careers/Banner.vue";
import Body from "@/components/careers/Body.vue";

export default {
  name: 'Careers',
  components: {
    CareersBanner,
    Body,
  }
}
</script>
