import Vue from 'vue'
import App from './App.vue'
import router from './router'

// Firebase Configurations

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASEURL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_FIREBASE_APPID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENTID
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// Bootstrap
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false

// Sidebar Menu
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
Vue.use(VueSidebarMenu)

// AOS Animation Library
import AOS from 'aos'
import 'aos/dist/aos.css'

window.AOS = AOS

// Vue Carousel
import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

// Vue Resize Observer
import VueResizeObserver from "vue-resize-observer";
import {API} from "@/API";
Vue.use(VueResizeObserver);

/*
// Vue GTag
import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { id: "UA-180687837-1" }
});
*/

//Mixins
Vue.mixin({
  mounted() {
      this.screenWidth = this.$el.offsetWidth;
      this.screenHeight = this.$el.offsetHeight;
  },
  data() {
      return {
        screenWidth: 0,
        screenHeight: 0,
        publicPath: process.env.BASE_URL
      }
  },
  methods: {
    isMobile() {
      return this.isSmall()
      // if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      //   return true
      // } else {
      //   return false
      // }
    },
    onResize({ width, height }) {
        this.screenWidth = width;
        this.screenHeight = height;
    },
    isSmall() {
        return this.screenWidth < 1024
    },
    willShowSideMenu(){
      return this.screenWidth <= 1024
    },
    isDarkNav(route){
      var darkNavRouteNames = ['Contact', 'Locations', 'Terms'];
      return darkNavRouteNames.includes(route);
    },
    navType(route){
      var contactNavRoutes = ['Contact', 'Locations'];
      if(contactNavRoutes.includes(route)){
        return "ContactNav"
      }else{
        return "RegularNav"
      }
    }
  },
})

new Vue({
  router,
  created () {
    AOS.init({
      duration: 800, // values from 0 to 3000, with step 50ms
      once: true, // whether animation should happen only once - while scrolling down
    })
  },
  render: h => h(App),
}).$mount('#app')

API.initialize()