<template>
  <div class="banner luxury-amenities">
    <NavigationBar />
    <div class="banner-content" data-aos="fade-in">
      <router-link to="/"><img class="ease-logo" src="@/assets/ease-hospitality-logo.svg" alt="Ease Hospitality"/></router-link>
      <h1>Tenant Amenities</h1>
    </div>
    <div class="scroll-to-bottom-container">
      <img src="@/assets/down-arrow.svg" alt="Scroll Down"/>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/styles/banner.scss';

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script>
import NavigationBar from '@/components/NavigationBar.vue'
export default {
  name: 'LuxuryAmenitiesBanner',
  components: {
    NavigationBar,
  }
}

</script>

