const moment = require("moment")
const v = require("voca")

export class BlogListItemViewModel {
    constructor(data) {
        this.title = data.title
        var desc = v.stripTags(data.body)
        desc = v.truncate(desc, 150)
        this.desc = desc
        this.image = data.thumbnail_filtered
        this.date_posted = moment(data.published_at).format("MMM D, YYYY");
        this.author = data.author;
        this.link = window.location.origin + "/blog/" + data.slug
    }
}
//
// function stripHtml(html)
// {
//     let tmp = document.createElement("DIV");
//     tmp.innerHTML = html;
//     return tmp.textContent || tmp.innerText || "";
// }