<template>
  <div class="banner home light-bg">
    <NavigationBar />
    <div class="banner-content" data-aos="fade-in">
      <router-link to="/"><img class="ease-logo" src="@/assets/ease-hospitality-logo.svg" alt="Ease Hospitality Careers" /></router-link>
    </div>
    <div class="scroll-to-bottom-container">
    </div>
  </div>
</template>

<style lang="scss">
@import '@/styles/banner.scss';

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script>
import NavigationBar from '@/components/NavigationBar.vue'
export default {
  name: 'CareersBanner',
  components: {
    NavigationBar,
  }
}
</script>
