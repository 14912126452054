<template>
  <div class="press-page">
    <TermsBanner />
    <Body />
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import TermsBanner from "@/components/terms/Banner.vue";
import Body from "@/components/terms/Body.vue";

export default {
  name: 'Terms',
  components: {
    TermsBanner,
    Body,
  }
}
</script>
