<template>
  <div class="virtual">
    <L1345Banner />
    <L1345Body />
  </div>
</template>

<script>
import L1345Banner from "@/components/locations/virtual/Banner.vue";
import L1345Body from "@/components/locations/virtual/Body.vue";

export default {
  name: 'Virtual',
  components: {
    L1345Banner,
    L1345Body
  }
}
</script>
