<template>
  <div id="luxury-amenities">
    <!-- Discover @Ease 1345, a modern energized -->
    <!-- Video -->
    <div class="gallery-type-section text-centered" data-aos="fade-in">
      <!-- First Vid -->
      <div class="row gallery-block">

        <div class="col-md-6" data-aos="fade-right" data-aos-offset="600">
          <h5 class="video-title">@Ease 605 Tenant Lounge</h5>
          <video class="space-preview-video"  preload autoplay loop controls muted poster="@/assets/locations/Poster_605CommonSpace.png">
            <source src="@/assets/locations/605CommonSpace.mp4" type="video/mp4">
            <source src="@/assets/locations/605CommonSpace.webm" type="video/webm">
            Your browser does not support the video tag.
          </video>
        </div>
        <!-- Second Vid -->
        <div class="col-md-6" data-aos="fade-left" data-aos-offset="600">
            <h5 class="video-title">@Ease 1345 Tenant Lounge</h5>
            <video class="space-preview-video"  preload autoplay loop controls muted poster="@/assets/locations/Poster_1345CommonSpace.png">
              <source src="@/assets/locations/1345CommonSpace.mp4" type="video/mp4">
              <source src="@/assets/locations/1345CommonSpace.webm" type="video/webm">
              Your browser does not support the video tag.
            </video>
        </div>
      </div>
    </div>

    <!-- Separator -->
    <img class="gradient-separator" src="@/assets/about-us/gradient-separator.png" alt="Divider"/>

    <!-- Every Amenity You Need to Succeed -->
    <div class="row text-image center-aligned top-section" data-aos="fade-in" data-aos-offset="200">
      <div class="col-md-12 full-text sub-top text-content">
        <h2>Every Amenity You Need to Succeed</h2>
        <p>Ease Hospitality provides building tenants with luxury amenities that anticipate and exceed their needs. From a relaxing lounge, to a revitalizing fitness center, gourmet cuisine, and the latest technology seamlessly woven throughout, everything has been planned to help tenants feel at ease.</p>
      </div>
    </div>

    <!-- Meetings & Events -->
    <div class="row text-image right-aligned dark-bg fabric-bg overflow-image amenities-container">
      <div class="col-md-12">
        <!-- Amenities Carousel -->
        <carousel
          class="fade-effect ameneties-carousel"
          :per-page=1
          @pageChange="handlePageChange"
          :value=currentAmenityIdx
          :paginationEnabled=false>
          <!-- Image 1 Slide Sample -->
          <slide v-for="(amenity, index) in this.amenities" :key="`amenity-${index}`">
            <div class="row">
              <div class="col-md-6 image-content">
                <img :src="amenity.image" alt="Amenitie Poster"/>
              </div>
              <div class="col-md-6 text-content">
                <div class="Aligner">
                  <div class="Aligner-item Aligner-item--top"></div>
                  <div class="Aligner-item">
                    <img class="logo-gfx" :src="amenity.gfx" alt="Amenitie Logo"/>
                    <h1>{{amenity.title}}</h1>
                    <p>{{amenity.body}}</p>
                  </div>
                  <div class="Aligner-item Aligner-item--bottom"></div>
                </div>
              </div>
            </div>
          </slide>

          <!-- End of Carousel -->
        </carousel>

        <div class="container bullets dark-bg">
          <div :class="{selected: this.currentAmenityIdx == 0}" @click="goToAmenityPage(0)">
            <p>Tenant Lounge</p>
          </div>
          <div :class="{selected: this.currentAmenityIdx == 1}" @click="goToAmenityPage(1)">
            <p>Meetings &amp; Events</p>
          </div>
          <div :class="{selected: this.currentAmenityIdx == 2}" @click="goToAmenityPage(2)">
            <p>Food &amp; Beverage</p>
          </div>
          <div :class="{selected: this.currentAmenityIdx == 3}" @click="goToAmenityPage(3)">
            <p>Wellness</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Separator -->
    <img class="gradient-separator" src="@/assets/about-us/gradient-separator.png" aria-hidden="true" alt="Divider"/>

    <!-- @Ease App -->
    <!-- <div class="row app-preview center-aligned marble-bg text-centered">
      <div class="col-lg-12" data-aos="fade-in" data-aos-offset="200">
        <div class="row header">
          <div class="col-lg-12">
            <h1>@Ease Workplace App</h1>
            <p>The @Ease Workplace App is your secure key to unlocking and easily accessing the services and amenities of the @Ease experience beginning at the touch-free entry into the property. The App seamlessly integrates any purchases and reservations into one easy-to-use checkout experience, allowing you to reserve meeting rooms, schedule fitness classes and massages, or order meals through the cafe or vertical catering. <strong><em><sup>*</sup>For Tenants Only</em></strong></p>
            <div class="download-buttons">
              <a href="https://apps.apple.com/kh/app/ease-workplace/id1526255927" target="_blank"><img src="@/assets/luxury-amenities/app-store-transparent.svg" alt="Apple App Store"/></a>
              <a href="https://play.google.com/store/apps/details?id=com.risesoftware.atease&hl=en_US&gl=US" target="_blank"><img src="@/assets/luxury-amenities/play-store-transparent.svg" alt="Google Play Store"/></a>
            </div>
          </div>
        </div>
        <div class="row app-preview-container">
          <div class="col-lg-4">
            <div class="row app-label">
              <div class="col-lg-12" data-aos="fade-right" data-aos-offset="200" data-aos-duration="500">
                <h2>Meeting Rooms</h2>
                <p>Reserve meeting rooms, quickly and conveniently.</p>
              </div>
            </div>
            <div class="row app-label" data-aos="fade-right" data-aos-offset="300" data-aos-duration="500">
              <div class="col-lg-12">
                <h2>Food &amp; Beverage</h2>
                <p>Order Grab-n-Go meals and coffees from the cafe.</p>
              </div>
            </div>
            <div class="row app-label" data-aos="fade-right" data-aos-offset="400" data-aos-duration="500">
              <div class="col-lg-12">
                <h2>Fitness &amp; Wellness</h2>
                <p>Reserve a place in our membership-based fitness and wellness classes.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 image-container">
            <div class="app-mobile-container">

               <img class="app-preview-image" src="@/assets/luxury-amenities/iphone-11-frame.png" alt="App Preview"/>
            </div>

          </div>
          <div class="col-lg-4" data-aos="fade-left" data-aos-offset="200" data-aos-duration="500">
            <div class="row app-label">
              <div class="col-lg-12">
                <h2>Massage Reservations</h2>
                <p>Schedule a restorative massage with one of our licensed professionals.</p>
              </div>
            </div>
            <div class="row app-label">
              <div class="col-lg-12" data-aos="fade-left" data-aos-offset="300" data-aos-duration="500">
                <h2>Building Access</h2>
                <p>Integrate with the building access and visitor management system.</p>
              </div>
            </div>
            <div class="row app-label">
              <div class="col-lg-12" data-aos="fade-left" data-aos-offset="400" data-aos-duration="500">
                <h2>Vertical Catering</h2>
                <p>Arrange for vertical catering, directly to your floor.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- GOES IN MIDDLE SECTION OF APP <video class="app-preview-video" preload autoplay loop>
      <source src="@/assets/luxury-amenities/iphone-preview.mp4" type="video/mp4">
      <source src="@/assets/luxury-amenities/iphone-preview.webm" type="video/webm">
      Your browser does not support the video tag.
    </video> -->

    <!-- Explore @ease Luxury Amenities -->
    <div class="gallery-type-section text-centered" data-aos="fade-in">
      <!-- Header -->
      <div class="row">
        <div class="col-md-12">
          <h1>Explore <br>Amenities Space</h1>
        </div>
      </div>

      <div class="gallery-type-section text-centered" data-aos="fade-in">
        <div class="row">
          <div class="col-md-12 full-width" style="margin-bottom: 50px;" data-aos-offset="600">
            <img src="@/assets/luxury-amenities/gallery/gallery9.png" alt="Amenitie Gallery 9 Photo"/>
          </div>
        </div>
      </div>

      <!-- First Block -->
      <div class="row gallery-block">
        <div class="col-md-7 full-width" data-aos="fade-right" data-aos-offset="600">
            <img src="@/assets/luxury-amenities/gallery/gallery1.png" alt="Amenitie Gallery 1 Photo"/>
        </div>
<!--         <div class="col-md-5">
          <div class="Aligner">
            <div class="Aligner-item Aligner-item--top"></div>
            <div class="Aligner-item" data-aos="fade-left" data-aos-offset="600" data-aos-duration="800">
              <img src="@/assets/luxury-amenities/gallery/gallery2.png" />
            </div>
            <div class="Aligner-item Aligner-item--bottom"></div>
          </div>
        </div> -->
      </div>

      <!-- Second Block -->
      <div class="row gallery-block">
        <div class="col-md-7 overflow-bottom additional-padding" data-aos="fade-right" data-aos-offset="900" data-aos-duration="800">
          <img src="@/assets/luxury-amenities/gallery/gallery6.png" alt="Amenitie Gallery 6 Photo"/>
        </div>
        <div class="col-md-5 full-width" data-aos="fade-left" data-aos-offset="900" data-aos-duration="800">
          <img src="@/assets/luxury-amenities/gallery/gallery4.png" alt="Amenitie Gallery 4 Photo"/>
        </div>
      </div>

      <!-- Second Block -->
      <div class="row gallery-block">
        <div class="col-md-7 overflow-bottom additional-padding" data-aos="fade-right" data-aos-offset="900" data-aos-duration="800">
          <img src="@/assets/luxury-amenities/gallery/gallery5.png" alt="Amenitie Gallery 5 Photo"/>
        </div>
        <div class="col-md-5 full-width" data-aos="fade-left" data-aos-offset="900" data-aos-duration="800">
          <img src="@/assets/luxury-amenities/gallery/gallery3.png" alt="Amenitie Gallery 3 Photo"/>
        </div>
      </div>

      <!-- Third Block -->
      <div class="row gallery-block">
        <div class="col-md-12 full-width extra-vertical-margin" data-aos="fade-down" data-aos-offset="800" data-aos-duration="900">
          <img src="@/assets/luxury-amenities/gallery/gallery8.png" alt="Amenitie Gallery 8 Photo"/>
        </div>
      </div>

      <!-- End of Gallery Section -->
    </div>

    <!-- We'd Love To -->
    <div class="text-full-width text-centered contact-section" data-aos="fade-in">
      <div class="Aligner">
        <div class="Aligner-item Aligner-item--top"></div>
        <div class="Aligner-item">
          <h1>We'd Love To<br>
          Hear From You</h1>
          <p>Please let us know how we can be of assistance.</p>
          <router-link to="/contact">Contact Us</router-link>
        </div>
        <div class="Aligner-item Aligner-item--bottom"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/styles/page-body.scss';
#luxury-amenities{
  .video-title {
    padding:0 0 16px 0;
  }
  .space-preview-video {
    max-width: 100%;
  }
  .link {
    color: $gold;
  }

  .nft-section {
    background: linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8) ), url(~@/assets/luxury-amenities/605-nft.png);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 0 0, center center;
    padding: 250px 10% !important;
    margin-bottom: 115px;
    h1{
      font-size: 42px;
    }

  .nft-text {
    padding: 2em;
  }

  .nft-video {
    max-width: 100%;
    margin-top: 2em;
  }
    p{
      font-size: 22px;
    }
  }

  .gallery-type-section {
    padding-top: 90px;
  }
  .app-preview{
    color: $palm-green;
    .row.header{
      width: 50%;
      margin: 0 auto;
      h1{
        margin-top: 70px;
        margin-bottom: 20px;
        font-size: 42px;
      }
      p{
        font-size: 21px;
      }
    }
    div.download-buttons{
      a{
        margin-right: 20px;
      }
    }
    .image-container{
      position: relative;
      min-height: 967px;
    }
    .app-mobile-container{
      margin: 0 auto;
      width: 495px;
      position: absolute;
      left: -100%;
      right: -100%;

      img.app-preview-image{
        width: 100%;
      }
      .app-preview-video{
        position: absolute;
        top: 75px;
        width: 378px;
        left: 59px;
        border-radius: 32px;
      }
    }
    .app-preview-container{
      margin-top: 100px;
      margin-bottom: 135px;
      .row.app-label{
        margin: 100px 0;
        p{
          padding: 0 15%;
          font-size: 21px;
        }
      }
    }
  }
  .amenities-container{
    .text-container{
      margin-top: 50px;
      // height: 65%;
    }
  }
  .fabric-bg{
    background: $black;
    // background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(~@/assets/luxury-amenities/fabric-bg.png);
    // background-repeat: no-repeat !important;
    // background-size: cover !important;
    div.text-content{
      padding-right: 9% !important;
      padding-left: 9% !important;
      width: 100% !important;
        .Aligner{
          padding-bottom: 100px;
        }
    }
    p{
      font-size: 21px;;
    }
  }
  .marble-bg{
    background: url(~@/assets/luxury-amenities/marble-bg.png);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 50px 0;
  }

  /** Slide Transition */
  .VueCarousel{
    &.img-wrapper{
      width: 99%;
    }
  }
  .bullets{
    position: absolute;
    bottom: 5%;
    right: 0;
    width: 30% !important;
    margin-right: 10%;
    margin-left: 65%;
    p{
      font-size: 16px;
      line-height: 20px;
    }
  }
  .ameneties-carousel{
    margin: -40px 0;
  }
  .row.expanded-row{
    margin-bottom: 100px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1920px) {
    .image-content {
      img {
        height: 700px !important;
      }
    }
  }

  /** Ipad */
  @media only screen and (min-width: 767px) and (max-width: 1490px) {
    .ameneties-carousel{
      .text-content{
        h1{
          font-size:30px !important;
        }
        p{
          font-size: 18px !important;
        }
      }
    }
  }

  /** Ipad */
  @media only screen and (min-width: 1024px) and (max-width: 1156px) {
    .bullets p{
      font-size: 16px;
      line-height: 20px;
      padding: 0 10px;
    }
  }

  /** Mobile */
  @media only screen and (max-width: 1023px) {
    .video-title {
      padding-top: 24px;
    }
    .app-preview{
      color: $palm-green;
      padding-bottom: 0px;
    }
    .row.header{
      width: 100% !important;
      h1{
        font-size: 35px !important;
      }
      p{
        font-size: 16px !important;
      }
    }

    .bullets{
      position: relative;
      margin: 100px auto 50px auto;
      width: 80% !important;
    }
    .row.app-label{
      margin: 0 !important;
    }
    .image-container{
      position: static !important;
    }
    .text-content{
      .Aligner{
        padding-bottom: 0 !important;
      }
    }

    .gallery-type-section {
      h1{
        font-size: 28px !important;
      }
      p{
        margin: 0 20%;
      }
      img{
        padding-top: 20px;
      }
      .extra-vertical-margin{
        margin: 40px 0;
        &.bottom{
          margin-bottom: 0;
        }
      }
    }
  }

  @media only screen and (max-width: 991px){

    .image-container{
      min-height: 781px !important;
    }
    .app-mobile-container{
      position: relative !important;
      left: 0 !important;
      right: 0 !important;
    }
  }

  @media only screen and (max-width: 491px){
    .image-container{
      min-height: 586px !important;
    }
    .app-mobile-container{
      width: 400px !important;
      .app-preview-video{
        width: 305px !important;
        top: 116px !important;
        left: 48px !important;
      }
    }
  }

  @media only screen and (max-width: 398px){
    .app-mobile-container{
      width: 300px !important;
      .app-preview-video{
          width: 230px !important;
          top: 86px !important;
          left: 35px !important;
      }
    }
  }
}
</style>

<script>
export default {
  name: 'LuxuryAmenitiesBody',
  data() {
    return{
      currentAmenityIdx: 0,
      amenities: [
        {
          gfx: require(`@/assets/home/banner-icons/tenant-lounge.svg`),
          image: require(`@/assets/luxury-amenities/gallery-tenant.png`),
          title: "Tenant Lounge",
          body: "Our tenant lounges feature anything from the largest terrarium in New York City boasting more than 400 live plants, to an exclusive custom designed and built ping pong table, to a curated gallery showcased on a variety of screens carried throughout our spaces."
        },
        {
          gfx: require(`@/assets/about-us/meetings.svg`),
          image: require(`@/assets/luxury-amenities/gallery-meetings.png`),
          title: "Meetings & Events",
          body: "Ease grants you access to a full service destination with everything you need to succeed. Our dedicated planning professionals and five-star hospitality services ensure an unforgettable experience with state-of-the-art technology, on-site support and personalized touches at every turn for your next meeting, conference, or event."
        },
        {
          gfx: require(`@/assets/home/banner-icons/food-beverage.svg`),
          image: require(`@/assets/luxury-amenities/gallery-food.png`),
          title: "Food & Beverage",
          body: "Our healthy menus are constantly evolving to incorporate seasonal ingredients that are local, sustainable and suitable for an array of dietary needs. Enjoy delicious meals, fresh pastries and barista-crafted coffees throughout the day from the Palm Rose cafe."
        },
        {
          gfx: require(`@/assets/home/banner-icons/wellness.svg`),
          image: require(`@/assets/luxury-amenities/gallery-wellness.png`),
          title: "Wellness",
          body: "Our wellness program is tailored to the individual experience. We are actively selling memberships onsite, to inquire corporate and individual memberships kindly email fit@easehospitality.com or speak to your onsite general manager to understand how you company might be able to participate wellness programs."
        }
      ]
    }
  },
  methods: {
    goToAmenityPage(pageIdx){
      this.currentAmenityIdx = pageIdx
    },
    handlePageChange(page){
      this.currentAmenityIdx = page
    }
  }
}
</script>
