const moment = require("moment")

export class NewsCardViewModel {
    constructor(data) {
        this.title = data.title;
        this.image = data.thumbnail_filtered;
        this.date_posted = moment(data.published_at).format("D.M.YYYY");
        this.post_type = data.post_type;
        this.link = data.link;
    }
}