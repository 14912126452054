<template>
  <div class="home-page">
    <!-- <HomeBanner v-if="!isMobile()" />
    <OurStory v-if="!isMobile()" />
    <HomeMobileBanner v-if="isMobile()" />
    <BeAtEase v-if="isMobile()" /> -->
    
    <!-- <HomeBanner v-if="isMobile()" />
    <OurStory v-if="isMobile()" />
    <HomeMobileBanner v-if="!isMobile()" />
    <BeAtEase v-if="!isMobile()" /> -->
    
    <!-- Desktop -->
    <HomeBanner />
    <OurStory />

    <!-- Mobile -->
    <!-- <HomeMobileBanner />
    <BeAtEase /> -->
  </div>
</template>

<script>
// <!-- Desktop -->
import HomeBanner from "@/components/home/Banner.vue";
import OurStory from "@/components/home/OurStory.vue";
    // <!-- Mobile -->
// import HomeMobileBanner from "@/components/mobile/home/BlogPage.vue";
// import BeAtEase from "@/components/mobile/home/BeAtEase.vue";

export default {
  name: 'Home',
  components: {
    // <!-- Desktop -->
    HomeBanner,
    OurStory,
    // <!-- Mobile -->
    // HomeMobileBanner,
    // BeAtEase
  },
  methods: {
  }
}
</script>
