<template>
  <div class="about-us-page">
    <AboutUsBanner />
    <SeamlessSolutions />
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import AboutUsBanner from "@/components/about-us/Banner.vue";
import SeamlessSolutions from "@/components/about-us/SeamlessSolutions.vue";

export default {
  name: 'AboutUs',
  components: {
    AboutUsBanner,
    SeamlessSolutions,
  }
}
</script>
