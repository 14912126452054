<template>
  <div class="banner press">
    <NavigationBar />
    <div class="banner-content" data-aos="fade-in">
      <router-link to="/"><img class="ease-logo" src="@/assets/ease-logo.svg" alt="Ease Press"/></router-link>
      <h1>Press</h1>
      <!-- <p>As featured in Architectural Digest</p> -->
    </div>
    <div class="scroll-to-bottom-container" alt="Scroll to Bottom">
      <!-- <img src="@/assets/down-arrow.svg" /> -->
    </div>
  </div>
</template>

<style lang="scss">
@import '@/styles/banner.scss';

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script>
import NavigationBar from '@/components/NavigationBar.vue'
export default {
  name: 'NewsPressBanner',
  components: {
    NavigationBar,
  }
}
</script>

<style scoped>

</style>