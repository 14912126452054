export class RSVPForm {
    constructor(
        firstName,
        lastName,
        email,
        phone,
        company,
        title,
        eventType,
        location,
        guest,
        eventDate,
        eventRequirements,
        agreement
    ) {
        this.first_name = firstName
        this.last_name = lastName
        this.email = email
        this.phone = phone
        this.company = company
        this.title = title
        this.event_type = eventType
        this.location = location
        this.guest = guest
        this.event_date = eventDate
        this.event_requirements = eventRequirements
        this.agreement = agreement
    }

    static blank(location) {
        return new RSVPForm(
            '',
            '',
            '',
            '',
            '',
            '',
            'Select Event Type *',
            location,
            null,
            null,
            '',
            false
        )
    }
}