<template>
  <div id="blog">
    <!-- News Container -->
    <div class="news-container">
      <!-- Our Story -->
      <div v-for="(news, index) in this.news"
            :key="`news-${index}`"
            class="row text-image news-item"
            :class="{'left-aligned': index % 2 == 0,
                    'right-aligned': index % 2 != 0}"
            data-aos="fade-in"
            data-aos-offset="200">
        <div class="col-md-6 image-content"
            :class="{'order-md-2': index % 2 == 0,
                    'order-md-1': index % 2 != 0}">
          <div class="image-wrapper">
            <img :src="news.image" alt="Post Image"/>
          </div>
        </div>
        <div class="col-md-6 text-content"
            :class="{'order-md-1': index % 2 == 0,
                    'order-md-2': index % 2 != 0}">
          <div class="Aligner">
            <div class="Aligner-item Aligner-item--top"></div>
            <div class="Aligner-item">
              <h1>{{news.title}}</h1>
              <p>{{news.desc}}</p>
              <div class="news-footer">
                <p>{{news.date_posted}} | {{news.author}}</p>
                <a :href="news.link" target="_blank">Read More</a>
              </div>
            </div>
            <div class="Aligner-item Aligner-item--bottom"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Pagination -->
    <div class="pagination-section" :hidden="!this.hasPagination">
      <div class="row">
        <div class="col-md-5 previous-button pagination-column">
          <a :hidden="this.previousPageUrl == null" :href="this.previousPageUrl" >
            <p>NEWER POSTS</p>
          </a>
        </div>
        <div class="col-md-2 pagination-column">
          <a href="/"><img class="logo-gfx" src="@/assets/about-us/seamless.svg" alt="Ease Symbols"/></a>
        </div>
        <div class="col-md-5 next-button pagination-column">
          <a :hidden="this.nextPageUrl == null" :href="this.nextPageUrl">
            <p>OLDER POSTS</p>
          </a>
        </div>
      </div>
    </div>

    <loading :active.sync="isLoading"
             :is-full-page="true"></loading>
  </div>
</template>

<style lang="scss">
@import '../../styles/page-body.scss';
@import "../../styles/blog/news-press-list.scss";

#blog{
  .news-item{
    .image-content{
      .image-wrapper{
        background: #2A4735;
        /* height: 500px; */
      }
      img{
        height: 500px;
        &[src=""] {
          visibility: hidden;
        }
      }
    }

    .text-content{
      .news-footer{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        p{
          font-size: 18px;
          line-height: 26px;
        }
        a{
          margin-bottom: 16px;
          font-weight: bold;
          font-size: 18px;
          letter-spacing: 0.2em;
          text-transform: uppercase;
          color: $palm-green;
          border-bottom: 2px solid #EEC6BB;
        }
      }
    }
  }

  /** Mobile */
  @media only screen and (max-width: 1023px) {
    .text-content{
      .news-footer{
        display: block !important;
        margin: 0 auto;
        p{
          display: block !important;
        }
        a{
          display: block !important;
          text-align: center;
          margin: 0 25%;
        }
      }
    }
  }
}
</style>

<script>
import {API} from "@/API";
import {PressListItemViewModel} from "@/models/PressListItemViewModel";
import {PostType} from "@/enums/PostType";
import Loading from "vue-loading-overlay";

export default {
  name: 'PressList',
  components: {
    Loading
  },
  data: function(){
    return {
      isLoading: false,
      page: 1,
      news: [],
      previousPageUrl: null,
      nextPageUrl: null,
      hasPagination: false,
    }
  },
  methods: {
    fetchPress() {
      const $this = this;
      $this.isLoading = true
      API.getPosts(PostType.press, this.page)
          .then(function (response){
            const result = response.data
            $this.news = result.data.map(function (post){
              return new PressListItemViewModel(post);
            });
            $this.isLoading = false
            $this.hasPagination = result.last_page > 1

            $this.previousPageUrl = null;
            $this.nextPageUrl = null;
            if($this.hasPagination) {
              const base_url = `${window.location.origin}/press`
              if(result.current_page > 1) {
                $this.previousPageUrl = `${base_url}?page=${result.current_page - 1}`
              }
              if(result.current_page < result.last_page) {
                $this.nextPageUrl = `${base_url}?page=${result.current_page + 1}`
              }
            }
          })
    }
  },
  mounted: function(){
    if(this.$route.query.page){
      this.page = this.$route.query.page
    }
    this.fetchPress();
  },
}
</script>