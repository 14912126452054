<template>
    <div class="news-card">
        <img :src="viewModel.image" alt="Post Photo"/>
        <div class="title-and-details">
            <h4 class="title">{{ viewModel.title }}</h4>
            <div class="detail-row">
                <pre>{{ viewModel.date_posted }}   |   {{ viewModel.post_type.charAt(0).toUpperCase() + viewModel.post_type.substring(1) }}</pre>
                <a :href="viewModel.link" target="_blank">Read More</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            items: [],
            isLoading: false,

        };
    },
    props: ['viewModel'],
}
</script>

<style lang="scss" scoped>
    .news-card {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 27rem;
        margin-left: 1rem;
        margin-right: 1rem;
        padding-top: 2rem;
        img {
            width: 100%;
            min-height: 20rem;
            border-radius: 12px;
        }
    }

    .title-and-details {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .title {
        text-align: start;
        padding-top: 1rem;
    }

    .detail-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        pre {
          font-size: 18px;
          line-height: 26px;
          margin-top: 0;
          margin-bottom: 1rem;
          font-family: 'Minerva Modern', 'Recoleta';
        }
        a {
          margin-bottom: 16px;
          font-weight: bold;
          font-size: 18px;
          letter-spacing: 0.2em;
          text-transform: uppercase;
          border-bottom: 2px solid #EEC6BB;
        }
    }

    // Smaller screens
    @media only screen and (max-width: 1182px) {
        .news-card {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding-top: 2rem;
            max-width: 24rem;
            margin-left: 8rem;
            margin-right: 8rem;
            img {
                width: 100%;
                height: 16rem;
                border-radius: 12px;
                object-fit: cover;
            }
        }
    }

    // Tiny screens
    @media only screen and (max-width: 465px) {
        .news-card {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 22rem;
            padding-top: 2rem;
            padding-left: 1rem;
            padding-right: 1rem;
            img {
                width: 100%;
                height: 14rem;
                border-radius: 12px;

            }
        }

        .title {
            text-align: start;
            padding-top: 1rem;
            font-size: 1.5em;
        }

        .detail-row > pre {
            font-size: 1em;
            line-height: 26px;
            margin-top: 0;
            margin-bottom: 1rem;
            font-family: 'Minerva Modern', 'Recoleta';
        }

        .detail-row > a {
            margin-bottom: 16px;
            font-weight: bold;
            font-size: 0.8em;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            border-bottom: 2px solid #EEC6BB;
        }
    }
</style>