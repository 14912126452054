const moment = require("moment")

export class PressListItemViewModel {
    constructor(data) {
        this.title = data.title;
        this.desc = data.body;
        this.image = data.thumbnail_filtered;
        this.date_posted = moment(data.published_at).format("MMM D, YYYY");
        this.author = data.author;
        this.link = data.link;
    }
}