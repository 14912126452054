<template>
  <div class="location-1345">
    <L1345Banner />
    <L1345Body />
  </div>
</template>

<script>
import L1345Banner from "@/components/locations/1345/Banner.vue";
import L1345Body from "@/components/locations/1345/Body.vue";

export default {
  name: 'Location1345',
  components: {
    L1345Banner,
    L1345Body
  }
}
</script>
