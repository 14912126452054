import {Endpoints} from "@/Endpoint";
import axios from "axios";
import curlirize from 'axios-curlirize'
import {PostType} from "@/enums/PostType";
export class API {
    static base_url = process.env.VUE_APP_API_BASE_URL + 'api/'
    static instance = axios.create({
        baseURL: API.base_url,
        headers: {
            'Content-Type': 'application/json',
        }
    })

    static initialize() {
        curlirize(API.instance);
    }

    static async submitContact(form) {
        this.track()
        return API.instance.post(Endpoints.ContactForm, form)
    }

    static async submitRSVP(form) {
        this.track()
        return API.instance.post(Endpoints.RSVPForm, form)
    }

    static async getPosts(type = PostType.blog, page = 1) {
        const params = { 'page': page }
        return API.instance.get(Endpoints.PostList(type), {params: params})
    }

    static async getBlogPost(blog_id) {
        return API.instance.get(Endpoints.BlogPage(blog_id))
    }

    static async getEventPost(event_id) {
        return API.instance.get(Endpoints.EventPage(event_id))
    }

    static async track() {
        console.log("fbq")
        window.fbq('track', 'Lead', {
            value: 0,
        });

        console.log("lintrk")
        window.lintrk('track', { conversion_id: 10983132 });
    }
}