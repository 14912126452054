import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

import InPersonSpaces from '../views/Home.vue'
import AboutUs from '../views/AboutUs.vue'

import Locations from '../views/Locations.vue'
import L1345 from '../views/Locations/L1345.vue'
import L605 from '../views/Locations/L605.vue'
import Virtual from '../views/Locations/Virtual.vue'

import LuxuryAmenities from '../views/LuxuryAmenities.vue'
import NewsPress from '../views/NewsPress.vue'
import BlogPage from '../views/BlogPage.vue'
import EventPage from '../views/EventPage'
import Contact from '../views/Contact.vue'
import MobileApp from '../views/MobileApp.vue'

import Hours from '../views/Hours.vue'
import Careers from '../views/Careers.vue'
import Terms from '../views/Terms.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/in-person-spaces',
    name: 'In Person Spaces',
    component: InPersonSpaces
  },
  {
    path: '/about-us',
    name: 'About Us',
    component: AboutUs
  },
  {
    path: '/locations',
    name: 'Locations',
    component: Locations
  },
  {
    path: '/1345',
    name: '1345 Avenue of the Americas',
    component: L1345
  },
  {
    path: '/605',
    name: '605 Avenue of the Americas',
    component: L605
  },
  {
    path: '/virtual',
    name: 'Virtual',
    component: Virtual
  },
  {
    path: '/luxury-amenities',
    name: 'Luxury Amenities',
    component: LuxuryAmenities
  },
  {
    path: '/news-press',
    name: 'News & Press',
    component: NewsPress
  },
  {
    path: '/press',
    name: 'Press',
    component: NewsPress
  },
  {
    path: '/blog',
    name: 'Blog',
    component: NewsPress
  },
  {
    path: '/events',
    name: 'Event',
    component: NewsPress
  },
  {
    path: '/blog/:blog_id',
    name: 'BlogPage',
    component: BlogPage
  },
  {
    path: '/events/:event_id',
    name: 'EventPage',
    component: EventPage
  },
  {
    path: '/blog-draft',
    name: 'BlogPageDraft',
    component: BlogPage
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/mobile-app',
    name: 'Mobile App',
    component: MobileApp
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/careers',
    name: 'Careers',
    component: Careers
  },
  {
    path: '/hours',
    name: 'Hours',
    component: Hours
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

// eslint-disable-next-line no-unused-vars
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
        return {selector: to.hash}
    } else {
        return { x: 0, y: 0 }
    }
  }
})

export default router
