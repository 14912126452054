<template>
  <div id="meetings-body">
<!--     <div class="row text-image center-aligned top-section" data-aos="fade-in" data-aos-offset="200">
      <div class="col-md-12 full-text top text-content">
        <img class="logo-gfx" src="@/assets/about-us/seamless.svg" alt="Ease Hospitality Icon"/>
      </div>
    </div> -->
    <!-- Video -->
    <div class="gallery-type-section text-centered" data-aos="fade-in">
      <!-- First Vid -->
      <div class="row gallery-block">

        <div class="col-md-6">
          <h5 class="video-title">Tenant Lounge</h5>
          <video class="space-preview-video" preload autoplay loop controls muted poster="@/assets/locations/Poster_1345CommonSpace.png">
            <source src="@/assets/locations/1345CommonSpace.mp4" type="video/mp4">
            <source src="@/assets/locations/1345CommonSpace.webm" type="video/webm">
            Your browser does not support the video tag.
          </video>
        </div>
        <!-- Second Vid -->
        <div class="col-md-6">
            <h5 class="video-title">Conference Center</h5>
            <video class="space-preview-video" preload autoplay loop controls muted poster="@/assets/locations/Poster_1345ConferenceSpace.png">
              <source src="@/assets/locations/1345ConferenceSpace.mp4" type="video/mp4">
              <source src="@/assets/locations/1345ConferenceSpace.webm" type="video/webm">
              Your browser does not support the video tag.
            </video>
        </div>
      </div>
    </div>

    <!-- Meetings &amp; Events -->
    <div class="row text-image right-aligned dark-bg fabric-bg overflow-image overflow-horizontal">
      <div class="col-md-12">
        <div class="row the-space-row">
          <div class="col-md-8 image-content" data-aos="fade-right" data-aos-offset="200">
            <img src="@/assets/meetings-and-events-spaces/img-1345-map.png" alt="1345 Floorplan"/>
          </div>
          <div class="col-md-4 text-content" data-aos="fade-left" data-aos-offset="200">
            <div class="Aligner">
              <div class="Aligner-item Aligner-item--top"></div>
              <div class="Aligner-item">
                <h1>The Space</h1>
                <ul class="line-separated-list">
                  <li>5,500 sq ft of Flexible Meeting Space</li>
                  <li>Two Huddle Rooms</li>
                  <li>Event Capacity of up to 440 Guests* </li>
                  <li>Multi-Purpose Gallery </li>
                  <li>Home of New York City’s<br>
                    Largest Indoor Terrarium</li>
                  <li>400 sq ft Garden Wall</li>
                  <li>LEED Gold Certified</li>
                </ul>
              </div>
              <div class="Aligner-item Aligner-item--bottom"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Technology -->
    <div class="row text-image left-aligned overflow-image overflow-horizontal technology-section">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-7 order-md-2 image-content" data-aos="fade-left" data-aos-offset="200">
            <img src="@/assets/meetings-and-events-spaces/technology/tech9.png" alt="Technology Photo"/>
          </div>
          <div class="col-md-5 order-md-1 text-content" data-aos="fade-right" data-aos-offset="200">
            <div class="Aligner">
              <div class="Aligner-item Aligner-item--top"></div>
              <div class="Aligner-item">
                <h1>Technology</h1>
                <ul class="line-separated-list">
                  <li>30’, 8K LED Video Wall with<br>Multi-Display Capabilities </li>
                  <li>Complimentary High-Speed WiFi</li>
                  <li>Virtual and Hybrid Meeting Capabilities</li>
                  <li>Live Broadcast/Webinar Capabilities</li>
                  <li>Digital Branding Capabilities Throughout</li>
                  <li>Powered Meeting Tables</li>
                  <li>Onsite Technology Team</li>
                </ul>
              </div>
              <div class="Aligner-item Aligner-item--bottom"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- @Ease Meetings -->
    <div class="row text-image center-aligned experiences-section-1345 dark-bg">
      <div class="col-md-12 full-text text-content" data-aos="fade-in" data-aos-offset="200">
        <!-- Header -->
        <div class="row header">
          <div class="col-md-12">
            <h1>Ease Meetings<br>
              &amp; Experiences
            </h1>
            <p>Stay replenished and connected throughout your meetings with different tiers of our signature packages.</p>

              <p>Onsite events can be tailored to accommodate small parties or large private in-person events up to 250 and Hybrid Events are only limited by physical capacities. These all-inclusive packages include standard to premier technology and a selection of seasonal food &amp; beverage offerings allowing you to streamline your event planning process.</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Tier 1 -->
    <div class="row text-image left-aligned tiers-carousel">
      <div class="col-md-12">
        <div class="container bullets">
          <div :class="{selected: this.currentTierPage == 0}" @click="goToTierPage(0)">
            <p>TIER I</p>
          </div>
          <div :class="{selected: this.currentTierPage == 1}" @click="goToTierPage(1)">
            <p>TIER II</p>
          </div>
          <div :class="{selected: this.currentTierPage == 2}" @click="goToTierPage(2)">
            <p>RECEPTION</p>
          </div>
        </div>
        <carousel
          :per-page=1
          @pageChange="handlePageChange"
          :value=currentTierPage
          :paginationEnabled=false>
          <!-- Tier 1 Meeting Package -->
          <slide>
            <div class="row">
              <div class="col-md-7 order-md-2 image-content" data-aos="fade-in" data-aos-offset="200">
                <img src="@/assets/meetings-and-events-spaces/tiers/tier1.png" alt="Tier 1 Photo"/>
              </div>
              <div class="col-md-5 order-md-1 text-content" data-aos="fade-in" data-aos-offset="200">
                <div class="Aligner">
                  <div class="Aligner-item Aligner-item--top"></div>
                  <div class="Aligner-item">
                    <h1>Tier 1</h1>
                    <ul class="regular-list">
                      <li>General Session Meeting Space</li>
                      <li>Dedicated Meal Space</li>
                      <li>Express Guest Check-In</li>
                      <li>Graze – All Day Refreshments</li>
                      <li>Continental Breakfast</li>
                      <li>Sandwich Lunch<br>
                        <em>*AM and/or PM Breaks available as add-ons*</em>
                      </li>
                     <li>On-Site Service Support</li>
                     <li>Pre-Conference Event Planning Support</li>
                     <li>Pre-Conference Technology Planning Support</li>
                     <li>Standard In-Room Technology</li>
                    </ul>
<!--                     <a class="generic-link" target="_blank" :href="`${publicPath}sample-menu-reception.pdf`">Download PDF</a> -->
                  </div>
                  <div class="Aligner-item Aligner-item--bottom"></div>
                </div>
              </div>
            </div>
          </slide>

          <!-- Tier 2 Meeting Package -->
          <slide>
            <div class="row">
              <div class="col-md-7 order-md-2 image-content" data-aos="fade-in" data-aos-offset="200">
                <img src="@/assets/meetings-and-events-spaces/tiers/tier2.png" alt="Tier 2 Photo"/>
              </div>
              <div class="col-md-5 order-md-1 text-content" data-aos="fade-in" data-aos-offset="200">
                <div class="Aligner">
                  <div class="Aligner-item Aligner-item--top"></div>
                  <div class="Aligner-item">
                    <h1>Tier II</h1>
                    <ul class="regular-list">
                      <li>General Session Meeting Space</li>
                      <li>Dedicated Meal Space</li>
                      <li>Express Guest Check-In</li>
                      <li>Graze – All Day Refreshments</li>
                      <li>Hot Buffet Breakfast</li>
                      <li>Hot Buffet Lunch<br>
                        <em>*AM and/or PM Breaks available as add-ons*</em>
                      </li>
                      <li>On-Site Service Support</li>
                      <li>Pre-Conference Event Planning Support</li>
                      <li>Pre-Conference Technology Planning Support</li>
                      <li>Standard In-Room Technology</li>
                    </ul>
<!--                     <a class="generic-link" target="_blank" :href="`${publicPath}sample-menu-half-day.pdf`">Download PDF</a> -->
                  </div>
                  <div class="Aligner-item Aligner-item--bottom"></div>
                </div>
              </div>
            </div>
          </slide>

          <!-- Tier 3 Slide Sample -->
          <slide>
            <div class="row">
              <div class="col-md-7 order-md-2 image-content" data-aos="fade-in" data-aos-offset="200">
                <img src="@/assets/meetings-and-events-spaces/tiers/tier3.png" alt="Tier 3 Photo"/>
              </div>
              <div class="col-md-5 order-md-1 text-content" data-aos="fade-in" data-aos-offset="200">
                <div class="Aligner">
                  <div class="Aligner-item Aligner-item--top"></div>
                  <div class="Aligner-item">
                    <h1>Reception</h1>
                    <ul class="regular-list">
                      <li>Dedicated Reception Space</li>
                      <li>Express Guest Check-In</li>
                      <li>Open Bar, Specialty Cocktails Available Upon Request</li>
                      <li>Passed Hors D'oeuvres, Appetizer Platters, Food Stations, and much more!</li>
                      <li>On-Site Service Support</li>
                      <li>Pre-Conference Event Planning Support</li>
                    </ul>
<!--                     <a class="generic-link" href="#">Download PDF</a> -->
                  </div>
                  <div class="Aligner-item Aligner-item--bottom"></div>
                </div>
              </div>
            </div>
          </slide>

          <!-- End of Carousel -->
        </carousel>
      </div>
    </div>

    <!-- Separator -->
    <img class="gradient-separator" src="@/assets/about-us/gradient-separator.png" alt="Divider"/>

    <!-- Explore 1345 Avenue of the Americas -->
    <div class="gallery-type-section text-centered" data-aos="fade-in">
      <!-- Header -->
      <div class="row">
        <div class="col-md-12">
          <h1>Explore 1345 Avenue<br>Of the Americas</h1>
        </div>
      </div>

      <!-- First Block -->
      <div class="row gallery-block">
        <div class="col-md-7 full-width" data-aos="fade-right" data-aos-offset="200">
            <img src="@/assets/meetings-and-events-spaces/gallery/gallery12.png" alt="1345 Gallert 12 Photo"/>
        </div>
        <div class="col-md-5">
          <div class="Aligner">
            <div class="Aligner-item Aligner-item--top"></div>
            <div class="Aligner-item" data-aos="fade-left" data-aos-offset="200">
              <img src="@/assets/meetings-and-events-spaces/gallery/gallery16.png" alt="1345 Gallert 16 Photo"/>
            </div>
            <div class="Aligner-item Aligner-item--bottom"></div>
          </div>
        </div>
      </div>

      <!-- Second Block -->
      <div class="row gallery-block">
        <div class="col-md-7 overflow-bottom additional-padding" data-aos="fade-right" data-aos-offset="200">
          <img src="@/assets/meetings-and-events-spaces/gallery/gallery3.png" alt="1345 Gallert 3 Photo"/>
        </div>
        <div class="col-md-5 full-width" data-aos="fade-left" data-aos-offset="200">
          <img src="@/assets/meetings-and-events-spaces/gallery/gallery4.png" alt="1345 Gallert 4 Photo"/>
        </div>
      </div>

      <!-- Third Block -->
      <div class="row gallery-block">
        <div class="col-md-12 full-width extra-vertical-margin" data-aos="fade-down" data-aos-offset="200">
          <img src="@/assets/meetings-and-events-spaces/1345/gallery9.png" alt="1345 Gallert 9 Photo"/>
        </div>
      </div>

      <!-- Fourth Block -->
      <div class="row gallery-block">
        <div class="col-md-5 overflow-bottom" data-aos="fade-left" data-aos-offset="200" data-aos-easing="ease-in-sine">
          <img src="@/assets/meetings-and-events-spaces/gallery/gallery7.png" alt="1345 Gallert 7 Photo"/>
        </div>
        <div class="col-md-7" data-aos="fade-right" data-aos-offset="200">
          <img src="@/assets/meetings-and-events-spaces/1345/gallery2.png" alt="1345 Gallert 2 Photo" />
        </div>

      </div>

      <!-- Fifth Block -->
      <div class="row gallery-block">
        <div class="col-md-12 full-width extra-vertical-margin bottom" data-aos-offset="200">
          <img src="@/assets/meetings-and-events-spaces/gallery/gallery5.png" alt="1345 Gallert 5 Photo"/>
        </div>
      </div>

      <!-- End of Gallery Section -->
    </div>

    <!-- Map Block -->
    <div class="row map-spacing map-background" data-aos="fade-in" data-aos-offset="200">
      <div class="col-lg-12 full-width">
        <div class="row">
          <div class="col-lg-4 px-0 col-sm-12">
            <a href="https://goo.gl/maps/iTtF8oPBsWqFJ2uQA" target="_blank"><img src="@/assets/meetings-and-events-spaces/1345/map-1345.png" class="fit-image" alt="1345 Location Map"/></a>
          </div>
          <div class="col-lg-8 mb-4 pl-lg-5 col-sm-12">
            <div class="top-spacing map-fontstyle">
            <h2>Location</h2>
            <p>1345 Avenue of the Americas is conveniently located in the heart of New York City </p>
            </div>
            <div class="row">
              <div class="col-lg-4 map-fontstyle col-sm-12">
                <h5><b>NEARBY PARKING</b></h5>
                <p>NYC Parking - 141 W 54<sup>th</sup> St, NY 10019</p>
                <h5><b>NEARBY CROSS STREET</b></h5>
                <p>6th Ave. , between 54<sup>th</sup> &#38; 55<sup>th</sup> St.</p>
              </div>
              <div class="col-lg-4 map-fontstyle col-sm-12">
                <h5><b>NEARBY HOTELS</b></h5>
                <p><a class="map-link" target="_blank" href="https://www.hilton.com/en/hotels/nycnhhh-new-york-hilton-midtown/">Hilton Midtown</a><br/>1335 Avenue of the Americas, NY 10019</p>
                <p><a class="map-link" target="_blank" href="https://www.parkcentralny.com/">Park Central</a><br/> 870 7<sup>th</sup> Ave, NY 10019</p>
                <p><a class="map-link" target="_blank" href="https://www.warwickhotels.com/warwick-new-york">Warwick New York</a><br/> 65 W 54<sup>th</sup> St, NY 10019</p>
              </div>
              <div class="col-lg-4 map-fontstyle col-sm-12">
                <h5><b>DISTANCE FROM TRANSPORTATION</b></h5>
                <p>Port Authority Bus Terminal (1 mile)</p>
                <p class="mb-0">Penn Station (1.3 Miles)</p>
                <img src="@/assets/meetings-and-events-spaces/605/PennStation.png" alt="Penn Station Symbol"/>
                <p class="mb-0 mt-4">Grand Central Station (1.8 Miles)</p>
                <img src="@/assets/meetings-and-events-spaces/605/GrandCentralStation.png" alt="Grand Central Station Symbol"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <!-- We'd Love To -->
    <div class="text-full-width text-centered contact-section" data-aos="fade-in" :ref="'contact-section'">
      <div class="Aligner">
        <div class="Aligner-item Aligner-item--top"></div>
        <div class="Aligner-item">
          <h1>We'd Love To<br>
          Hear From You</h1>
          <p>Please let us know how we can be of assistance.</p>
          <router-link to="/contact">Contact Us</router-link>
        </div>
        <div class="Aligner-item Aligner-item--bottom"></div>
      </div>
    </div>

    <div :class="{sticky: true, 'downloads-section': true, 'text-centered': true, hidden: !this.floatingDownloadsVisible }">
      <div class="col-md-12">
        <a class="generic-link" v-b-modal.rfp-modal>Request for Proposal</a>
        <a class="generic-link" v-b-modal.virtual-modal>Virtual Tour</a>
        <a class="generic-link" :href="`${publicPath}Ease_1345_One_Sheet.pdf`" target="_blank">Capacity Chart</a>
<!--         <a class="generic-link" :href="`https://us1.list-manage.com/survey?u=1a3092e62599d5cf937fc332a&id=6c5e13d9ad&attribution=false`" target="_blank">Take a Survey</a> -->
        <a class="back-to-top-link" href="#"><img src="@/assets/down-arrow.svg" v-if="!isMobile()" alt="Back to Top"/></a>
      </div>
    </div>

    <!-- Virtual Modal Form -->
    <b-modal id="virtual-modal" content-class="virtual-modal" centered>
      <img class="close-button" src="@/assets/close.svg" @click="closeModal" />
      <div class="row">
        <iframe style="max-width:100%; width:100%;margin:0 auto;" src="https://www.web.allseated.com/AllSeatedMini.cfm?vendorid=161186&sub=66815" frameborder="0" allowfullscreen=""></iframe>
      </div>
    </b-modal>


    <!-- RFP Modal Form -->
    <b-modal id="rfp-modal" content-class="rfp-modal" centered>
      <img class="close-button" src="@/assets/close.svg" @click="closeModal" alt="Close"/>
      <div class="row">
        <div class="col-lg-6 address-info-container">
          <div class="contact-details">
            <h1>Request for proposal</h1>
            <h2 class="instr">Please complete the form to submit your inquiry.</h2>
            <p class="details">
              Ease Hospitality<br>
              299 Park Avenue<br>
              42nd Floor<br>
              New York, NY 10105
            </p>
            <p class="details">
              Phone: 212-201-0425<br>
              Email: info@easehospitality.com
            </p>
            <p class="follow">
              Follow Us
            </p>
            <div class="sns-icons">
                <a href="https://www.facebook.com/Ease-Hospitality-106482987830969" target="_blank" class="sns-logo"><img src="@/assets/contacts/facebook-dark-icon.svg" alt="Facebook"/></a>
                <a href="https://www.instagram.com/ease.hospitality" target="_blank" class="sns-logo"><img src="@/assets/contacts/instagram-dark-icon.svg" alt="Instagram"/></a>
                <a href="https://www.linkedin.com/company/easehospitality/" target="_blank" class="sns-logo"><img src="@/assets/contacts/linkedin-dark-icon.svg" alt="LinkedIn"/></a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 form-container">
          <div class="modal__col modal__col--right">
            <!-- Contact Form -->
            <form class="rfp-form" @submit.prevent="checkForm($event)" method="post">
              <h6>Contact Info</h6>
              <div class="form-group">
                <!-- First Name -->
                <input
                  type="text"
                  aria-label="first_name"
                  name="first_name"
                  placeholder="First Name *"
                  v-model="form.first_name"
                  required
                />

                <!-- Last Name -->
                <input
                  type="text"
                  aria-label="last_name"
                  name="last_name"
                  placeholder="Last Name *"
                  v-model="form.last_name"
                  required
                />
              </div>

              <div class="form-group">
                <!-- Email -->
                <input
                  type="email"
                  aria-label="email"
                  name="email"
                  placeholder="Email *"
                  v-model="form.email"
                  required
                />

                <!-- Phone -->
                <input
                  type="text"
                  aria-label="phone"
                  name="phone"
                  placeholder="Phone Number *"
                  v-model="form.phone"
                  required
                />
              </div>

              <div class="form-group">
                <!-- Company -->
                <input
                  type="text"
                  aria-label="company"
                  name="company"
                  placeholder="Company *"
                  v-model="form.company"
                  required
                />

                <!-- Job Title -->
                <input
                  type="text"
                  aria-label="title"
                  name="title"
                  placeholder="Job Title *"
                  v-model="form.title"
                  required
                />
              </div>

              <h6>Meeting Preference</h6>
              <!-- Reason for Contacting -->
              <div class="select">
                <select
                  aria-label="event_type"
                  name="event_type"
                  v-model="form.event_type"
                >
                  <option selected disabled>Select Event Type *</option>
                  <option value="meeting-event">Meetings &amp; Event</option>
                  <option value="meeting-hybrid">Hybrid In-Person</option>
                  <option value="meeting-other">Other</option>
                </select>
                <img src="@/assets/contacts/down-caret.svg" alt="Dropdown"/>
              </div>

              <!-- Location dropdown -->
              <div class="select">
                <select
                  aria-label="location"
                  name="location"
                  v-model="form.location"
                >
                  <option disabled>Location</option>
                  <option selected>1345</option>
                  <option>605</option>
                  <option>No Preference</option>
                </select>
                <img src="@/assets/contacts/down-caret.svg" alt="Dropdown"/>
              </div>

              <div class="form-group">
                <!-- Number of Attendees -->
                <input
                  type="number"
                  aria-label="guest"
                  name="guest"
                  placeholder="Number of Attendees *"
                  v-model="form.guest"
                  required
                />

                <!-- Desired Date -->
                <input
                  type="date"
                  aria-label="event_date"
                  name="event_date"
                  placeholder="Desired Date *"
                  v-model="form.event_date"
                  required
                />
              </div>

              <!-- Message -->
              <textarea rows="4" placeholder="Message" aria-label="event requirement" name="event_requirements" v-model="form.event_requirements">
              </textarea>

              <br />

               <vue-recaptcha ref="recaptcha" @verify="onVerify"  @expired="onExpired"
               :sitekey="bookingForm.siteKey" :loadRecaptchaScript="true">
               </vue-recaptcha>

              <div><p style="color: red">{{ bookingForm.pleaseTickRecaptchaMessage }}</p></div>

              <div class="checkbox-container">
                <input type="checkbox" aria-label="Terms" v-model="form.agreement" /> <label>I accept the <a href="#">terms of agreement</a>.</label>
                <button type="submit">SUBMIT</button>
              </div>

              <!-- End of Contact Form -->
            </form>
          </div>
        </div>
      </div>
    </b-modal>
    <loading :active.sync="isLoading"
    :is-full-page="fullPage"></loading>
  </div>
</template>

<style lang="scss">
@import '@/styles/page-body.scss';
#meetings-body{
  .map-link {
    color: #C28A49 !important;
    text-decoration: underline;

    &:after {
      content:'';
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-left: .25em;
      background: transparent url(~@/assets/right-arrow.svg) center 90% no-repeat;
      background-size: contain;
    }
  }
  .video-title {
    padding: 0 0 16px 0;
  }
  .space-preview-video {
    max-width: 100%;
  }

  .top-section{
    padding-top: 20px;
    padding-bottom: 150px;
  }
  .app-preview{
    .row.header{
      width: 50%;
      margin: 0 auto;
      h1{
        margin-top: 70px;
        margin-bottom: 20px;
        font-size: 42px;
      }
      p{
        font-size: 21px;
      }
    }
    div.download-buttons{
      a{
        margin-right: 20px;
      }
    }
    .image-container{
      position: relative;
    }
    img.app-preview-image{
      position: absolute;
      top: -100%;
      left: -100%;
      bottom: -100%;
      right: -100%;
      margin: auto;

    }

    .app-preview-container{
      margin-top: 100px;
      margin-bottom: 135px;
      .row.app-label{
        margin: 100px 0;
        p{
          padding: 0 15%;
          font-size: 21px;
        }
      }
    }
  }
  .the-space-row{
    padding: 120px 0 60px 0;
  }
  .fabric-bg.overflow-horizontal{
    background: $black;
    // background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(~@/assets/luxury-amenities/fabric-bg.png);
    // background-repeat: no-repeat !important;
    // background-size: cover !important;
    div.text-content{
      padding: 0 !important;
    }
    p{
      font-size: 21px;
      &.italicized-text{
        font-size: 14px !important;
        font-style: italic;
        margin-bottom: 50px;
      }
    }
    .Aligner-item{
      margin-left: -20%;
    }
  }

  .marble-bg{
    background: url(~@/assets/luxury-amenities/marble-bg.png);
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }

  .experiences-section-1345 {
    background: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url(~@/assets/meetings-and-events-spaces/1345/events1345.png);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 0 0, center center;
    padding: 250px 25% !important;
    margin-bottom: 115px;
    h1{
      font-size: 42px;
    }
    p{
      font-size: 22px;
    }
  }


  .holiday-section{
      background: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url(~@/assets/meetings-and-events-spaces/events-experiences/holiday1.png);
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-position: 0 0, left center;
      padding: 250px 10% !important;
      margin-bottom: 115px;
      h1{
        font-size: 42px;
      }
      p{
        font-size: 22px;
      }
    }

  .technology-section{
    padding:100px 0 !important;
  }

  .downloads-section{
    padding: 90px 0;
  }

  .sticky.downloads-section {
    padding: 30px 0;
    box-shadow: 0 -2px 8px rgb(0,0,0, .25);
    border-top: 4px solid #C28A49;
    padding: 30px 0;
    background: rgba(#F2EDE6, .75);
    backdrop-filter: blur(10px);
  }

  .tiers-carousel{
    max-width: 1440px;
    margin: 0 auto;
    ul{
      padding-left: 20px;
      padding-top: 16px;
      li{
        margin-bottom: 24px;
      }
    }
  }
  .sticky {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 24px 0;
    background:rgba($ivory, .85);
    box-shadow: 0 -1px 4px rgba($black, .25);
    border-top: 1px solid $gold;
    backdrop-filter: blur(8px);

    &.hidden{
      display: none;
    }
  }

  .bullets{
    justify-content: space-between;
  }

  /** Ipad */
  @media only screen and (min-width: 1024px) and (max-width: 1156px) {
    .the-space-row{
      .image-content{
        padding-right: 50px !important;
      }
      h1{
        font-size: 35px !important;
      }
      p{
        font-size: 16px !important;
      }
    }

    .line-separated-list{
      li{
        font-size: 16px !important;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
  /** Mobile */
  @media only screen and (max-width: 1023px) {
    .video-title {
      padding-top: 24px;
    }
    .the-space-row{
      padding: 0px 0 40px 0;
      .image-content{
        padding-bottom: 50px;
      }
    }

    .technology-section{
      padding:0 !important;
      .image-content{
        padding-bottom: 50px;
      }
    }
    .downloads-section{
      padding-bottom: 50px;
    }

    .experiences-section-1345 {
      padding: 40px 0 !important;
      .header {
        margin: 0;
      }
    }
    .row.header{
      width: 100% !important;
      h1{
        font-size: 35px !important;
      }
      p{
        font-size: 16px !important;
      }
    }

    .row.app-label{
      margin: 0 !important;
    }
    .image-container{
      position: static !important;
    }
    img.app-preview-image{
      margin: auto;
      width: 100%;
      max-width: 608px;
      position: static !important;

    }
    .fabric-bg.overflow-horizontal{
      .text-content{
        width: 100% !important;
      }
      .Aligner-item{
        margin: 0 auto;
      }
    }

    .tiers-carousel{
      .text-content{
        width: 100% !important;
        .generic-link{
          text-align: center;
        }
      }
    }

    .gallery-type-section{

      h1{
        font-size: 28px !important;
      }
      p{
        margin: 0 20%;
      }
      img{
        padding-top: 20px;
      }
      .extra-vertical-margin{
        margin: 40px 0;
        &.bottom{
          margin-bottom: 0;
        }
      }
    }
    /** End mobile */
  }
}

#virtual-modal {
  .modal-dialog{
    max-width: 80vw;
    min-height: 80vh;

    iframe {
      max-width: 80vw;
      min-height: 70vh;
    }
  }

  .modal-header{
    display: none;
  }

  .modal-footer{
    display: none;
  }
}

#rfp-modal{
  .modal-dialog{
    max-width: 100%;
    height: auto;
  }
  .contact-details{
    h1{
      font-size: 2.5rem;
      line-height: 2.625rem;
    }
    h2.instr{
      font-size: 16px;
      margin-bottom: 32px;
    }
    p.details{
      font-size: 20px;
      margin-bottom: 32px;
    }
    p.follow{
      font-size: 24px;
    }

    .sns-icons{
      display: inline-block;
      margin: 0 auto;
      .sns-logo + .sns-logo{
          margin-left: 18px;
      }
    }
  }
  .rfp-modal{
    position: relative;
    .close-button{
      position: absolute;
      top: -12%;
      right: -7%;
    }
    padding: 30px;
    background-color: $ivory;
    border-radius: 0;
    border-width: 0;
    .modal-header{
      display: none;
    }
    .modal-body{
      padding: 0;
      color: $palm-green;
      .image-content{
        .VueCarousel{
          height: 100%;

          img{
            object-fit: cover;
            width: 100%;
          }
        }
      }
      .text-content{
        padding: 0 8%;
        p.desc{
          font-size: 21px;
        }
        p.email{
          font-size: 16px;
          text-transform: uppercase;
          margin-bottom: 0;
        }
      }
    }
    .sns-logo-containers{
      margin-top: 15px;
    }
    .modal-footer{
      display: none;
    }
    /** Mobile */
    @media only screen and (max-width: 1248px) {
      .close-button{
        z-index: 999;
        top: 0 !important;
        right: 0 !important;
      }
    }
  }
  /** Mobile */
  @media only screen and (max-width: 1023px) {
    .modal-body{
      .text-content{
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        h1{
          font-size: 30px !important;
          margin-bottom: 10px !important;
        }
        p.desc{
          font-size: 16px !important;
        }
        p.email{
          font-size: 16px !important;
        }
      }
    }
    /** End of Mobile */
  }
}


.rfp-form{
  h6{
    font-size: 16px;
    font-weight: 600;
  }
  .form-group{
    display: flex;
    margin-bottom: 0;

    input + input{
      margin-left: 20px;
    }
  }
  .checkbox-container{
    margin-top: 33px;
    input{
      display:inline-block;
      width: auto;
      margin-right: 10px;
    }
    label{
      display: inline-block;
      color: $palm-green;
      a{
        color: $palm-green;
        text-decoration: underline;
      }
    }
  }
  input, textarea, select{
    display: block;
    width: 100%;
    font-size: 16px;
    justify-content: space-between;
  }

  input{
    background: transparent;
    border: 0;
    border-bottom: 1px solid $palm-green;
    margin-bottom: 33px;
    outline: none;

    &::placeholder{
      color: $palm-green;
    }
  }

  .select{
    position: relative;
    img{
      position: absolute;
      right: 0;
      bottom: 30%;
      height: 9.5px;
      width: 18px;
      object-fit: cover;
    }
  }
  select{
    background: transparent;
    border: 0px transparent solid;
    border-bottom: 1px solid $palm-green;
    margin-bottom: 33px;
    outline: none;
    color: $palm-green;

    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
  }

  textarea{
    background: transparent;
    padding: 20px;
    border-bottom: 1px solid $palm-green;
  }

  input:focus, textarea:focus, button:focus, select:focus{
    outline: none;
  }

  button{
    display: block;
    background: transparent;
    border: 0;
    border-bottom: 2px solid #EEC6BB;
    font-size: 18px;
    color: $palm-green;
    letter-spacing: 0.2em;
    margin-top: 20px;
  }
}

.map-spacing{
  /* margin-top: 140px;
  margin-left: 10%;
  margin-right: 10%; */
  margin-top: 140px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1470px;
}
.map-background{
  background-color: #FFF;
}
@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .map-spacing{
  margin-top: 140px;
  margin-left: 0% !important;
  margin-right: 0% !important;
  }
  .map-fontstyle{
  font-size: 16px !important;
  }
  .map-image1{
  object-fit:contain;
  height: 20px;
  width: auto;
  }
  .map-image2{
  object-fit:contain;
  height: 20px;
  width: auto;
  }
}
// Mobile
@media only screen and (max-width: 1023px){
  @media only screen and (min-width: 768px) and (max-width: 912px){
    .map-fontstyle{
    font-size: 18px !important;
    }
    .top-spacing{
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    }
  }
  @media only screen and (max-width: 767px){
    .map-spacing{
    display: block !important;
    margin-top: 140px;
    margin-left: 0% !important;
    margin-right: 0% !important;
    }
    .map-fontstyle{
    font-size: 16px !important;
    }
    .map-image1{
    object-fit:contain;
    height: 20px;
    width: auto;
    }
    .map-image2{
    object-fit:contain;
    height: 20px;
    width: auto;
    }
    .top-spacing{
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    }
  }
}
.top-spacing{
  margin-top: 50px;
  margin-bottom: 50px;
}
.map-fontstyle{
  font-size: 21px;
  color: #2A4735 !important;
}
.fit-image{
height: 100%;
width: 100%;
object-fit: cover;
}

</style>

<script>
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

// Import component
import Loading from 'vue-loading-overlay';
import {RSVPForm} from "@/models/RSVPForm";
import {API} from "@/API";

import VueRecaptcha from 'vue-recaptcha';

var recaptchaMessage = "Please complete the reCAPTCHA verification to proceed.";


export default {
  name: 'MeetingsEventsSpaceBody',
  data() {
    return{
      isLoading: false,
      fullPage: true,
      currentTierPage: 0,
      floatingDownloadsVisible: false,
      form: RSVPForm.blank('1345'),
      bookingForm: {
        recaptchaVerified: false,
        pleaseTickRecaptchaMessage: '',
        siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY
      }
    }
  },
  components: {
    Loading,
    VueRecaptcha
  },
  created () {
    window.addEventListener('scroll', this.meetingsDidScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.meetingsDidScroll);
  },
  methods: {
    goToTierPage(pageIdx){
      this.currentTierPage = pageIdx
    },
    handlePageChange(page){
      this.currentTierPage = page
    },
    meetingsDidScroll(){
      // console.log(e.target)
      var diff = (this.$refs['contact-section'].getBoundingClientRect().bottom - window.innerHeight)
      this.floatingDownloadsVisible = window.scrollY > 100 && diff > -150
    },
    closeModal(){
      this.$bvModal.hide('rfp-modal')
      this.currentMemberIdx = 0
    },

    onVerify() {
      this.bookingForm.pleaseTickRecaptchaMessage = '';
      this.bookingForm.recaptchaVerified = true;
    },

    onExpired() {
      this.bookingForm.pleaseTickRecaptchaMessage = recaptchaMessage;
      this.bookingForm.recaptchaVerified = false;
    },

    checkForm(event){
      if (!this.bookingForm.recaptchaVerified) {
        this.bookingForm.pleaseTickRecaptchaMessage = recaptchaMessage;
        return true; // prevent form from submitting
      }
      var $this = this
      event.preventDefault()

      if($this.form.agreement == false){
          Swal.fire(
            'Oops...',
            'You must accept the terms of agreement.',
            'info'
          )
        return;
      }

      $this.isLoading = true;
      API.submitRSVP(this.form)
        .then(function(/* response */){
          $this.isLoading = false
          // console.log(response)

          $this.form = RSVPForm.blank()

          Swal.fire(
            'Thank You!',
            'Your inquiry has been sent.',
            'success'
          )
        })
        .catch(function (/* error */) {
          $this.isLoading = false
          // console.log(error)
          // handle error
          Swal.fire(
            'Oops...',
            'Something went wrong!',
            'error'
          )
        })

    },
  }
}
</script>
