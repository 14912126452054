<template>
  <div id="hybrid-body">
    <!-- Thoughtfully designed by David Rockwell and Rockwell Group -->
    <div class="row text-image center-aligned top-section" data-aos="fade-in" data-aos-offset="200">
      <div class="col-md-12 full-text top text-content">
        <img class="logo-gfx" src="@/assets/about-us/seamless.svg" />
        <p>Ease Hospitality enhances the virtual and hybrid model of events for today’s ever-evolving workplace utilizing state-of-the-art cutting-edge hardware and software. @Ease creates an experience regardless of location to suit your company’s needs. @Ease produces customized events from live-training workshops, to corporate celebrations, to board meetings, and full-scale digital conferences. The design principles and attention to detail are carried through the tiered virtual packages blending the digital and physical plane.</p>
        <!-- <p>
          Virtual or Hybrid Meetings with Multi-Platform Conferencing Support
          Virtual or Hybrid Events
          Virtual ‘In-Room’ 3D experience with augmented reality capabilities
        </p> -->
      </div>
    </div>

    <div class="row text-image right-aligned dark-bg fabric-bg overflow-image overflow-horizontal">
      <div class="col-md-12">
        <!-- Meetings &amp; Events -->
        <div class="row the-space-row">
          <div class="col-md-12 col-lg-6 image-content" data-aos="fade-right" data-aos-offset="200">
            <img src="@/assets/meetings-and-events-spaces/img1-2.png" />
          </div>
          <div class="col-md-12 col-lg-6 text-content" data-aos="fade-left" data-aos-offset="200">
            <div class="Aligner">
              <div class="Aligner-item Aligner-item--top"></div>
              <div class="Aligner-item">
                <h1 class="features-title">Virtual and Hybrid Meeting Features</h1>
                <ul class="line-separated-list">
                  <li>Webcasting</li>
                  <li>Digital Branding</li>
                  <li>Networking & Engagement</li>
                  <li>Planning & Production Services</li>
                </ul>
              </div>
              <div class="Aligner-item Aligner-item--bottom"></div>
            </div>
          </div>
        </div>

        <!-- Technology -->
        <div class="row additional-feature">
          <div class="col-md-12 order-lg-2 col-lg-7 image-content" data-aos="fade-left" data-aos-offset="200">
            <img src="@/assets/meetings-and-events-spaces/technology/tech10.png" />
          </div>
          <div class="col-md-12 order-lg-1 col-lg-5 text-content" data-aos="fade-right" data-aos-offset="200">
            <div class="Aligner">
              <div class="Aligner-item Aligner-item--top"></div>
              <div class="Aligner-item">
                <h1 class="features-title">Hybrid Meeting Rooms</h1>
                <ul class="line-separated-list">
                  <li>Built-in Cameras</li>
                  <li>Ultra-High Definition In-Room Displays</li>
                  <li>High Quality Audio Systems</li>
                  <li>High Speed Internet and Partitioned Wifi</li>
                  <li>On-Site Technology Support</li>
                </ul>
              </div>
              <div class="Aligner-item Aligner-item--bottom"></div>
            </div>
          </div>
        </div>

        <!-- End of features section -->
      </div>
    </div>

    <!-- Carousel -->
    <div class="row text-image left-aligned tiers-carousel">
      <div class="col-md-12">
        <div class="container bullets">
          <div :class="{selected: this.currentTierPage == 0}" @click="goToTierPage(0)">
            <p>Option 1</p>
          </div>
          <div :class="{selected: this.currentTierPage == 1}" @click="goToTierPage(1)">
            <p>Option 2</p>
          </div>
          <div :class="{selected: this.currentTierPage == 2}" @click="goToTierPage(2)">
            <p>Option 3</p>
          </div>
        </div>
        <carousel
          :per-page=1
          @pageChange="handlePageChange"
          :value=currentTierPage
          :paginationEnabled=false>
          <!-- Tier 1 Slide Sample -->
          <slide>
            <div class="row">
              <div class="col-md-7 order-md-2 image-content" data-aos="fade-in" data-aos-offset="200">
                <img src="@/assets/meetings-and-events-spaces/hybrid/option-1.png" />
              </div>
              <div class="col-md-5 order-md-1 text-content" data-aos="fade-in" data-aos-offset="200">
                <div class="Aligner">
                  <div class="Aligner-item Aligner-item--top"></div>
                  <div class="Aligner-item">
                    <h1>@Ease Hybrid Meetings</h1>
                    <ul class="regular-list">
                      <li>Multi-Platform Videoconference Support</li>
                      <li>Built-In AV Integration (Camera, Microphone, PC)</li>
                      <li>On-Site Technology Support</li>
                      <li>High Speed Wi-Fi</li>
                      <li>Hardline Internet Connection</li>
                    </ul>
                    <!-- <a class="generic-link" target="_blank" :href="`${publicPath}sample-menu-reception.pdf`">Download PDF</a> -->
                  </div>
                  <div class="Aligner-item Aligner-item--bottom"></div>
                </div>
              </div>
            </div>
          </slide>

          <!-- Tier 2 Slide Sample -->
          <slide>
            <div class="row">
              <div class="col-md-7 order-md-2 image-content" data-aos="fade-in" data-aos-offset="200">
                <img src="@/assets/meetings-and-events-spaces/hybrid/option-2.png" />
              </div>
              <div class="col-md-5 order-md-1 text-content" data-aos="fade-in" data-aos-offset="200">
                <div class="Aligner">
                  <div class="Aligner-item Aligner-item--top"></div>
                  <div class="Aligner-item">
                    <h1>@Ease Virtual &amp; Hybrid Events</h1>
                    <ul class="regular-list">
                      <li>General and Breakout Sessions</li>
                      <li>Technology Checks</li>
                      <li>Event Analytics</li>
                      <li>Networking and Attendee Engagement</li>
                      <li>Branding Customization</li>
                      <li>Flexible Production Support</li>
                    </ul>
                    <!-- <a class="generic-link" target="_blank" :href="`${publicPath}sample-menu-half-day.pdf`">Download PDF</a> -->
                  </div>
                  <div class="Aligner-item Aligner-item--bottom"></div>
                </div>
              </div>
            </div>
          </slide>

          <!-- Tier 3 Slide Sample -->
          <slide>
            <div class="row">
              <div class="col-md-7 order-md-2 image-content" data-aos="fade-in" data-aos-offset="200">
                <img src="@/assets/meetings-and-events-spaces/hybrid/option-3.png" />
              </div>
              <div class="col-md-5 order-md-1 text-content" data-aos="fade-in" data-aos-offset="200">
                <div class="Aligner">
                  <div class="Aligner-item Aligner-item--top"></div>
                  <div class="Aligner-item">
                    <h1>@Ease Virtual In-Room Experience</h1>
                    <ul class="regular-list">
                      <li>Virtual 3D Environment</li>
                      <li>General and Breakout Sessions</li>
                      <li>Technology Checks</li>
                      <li>Event Analytics</li>
                      <li>Networking, Attendee, and Sponsorship Engagement</li>
                      <li>Venue Branding Features</li>
                      <li>Flexible Production Support</li>
                    </ul>
                    <!-- <a class="generic-link" href="#">Download PDF</a> -->
                  </div>
                  <div class="Aligner-item Aligner-item--bottom"></div>
                </div>
              </div>
            </div>
          </slide>

          <!-- End of Carousel -->
        </carousel>
      </div>
    </div>

    <!-- Separator -->
    <img class="gradient-separator" src="@/assets/about-us/gradient-separator.png" />

    <!-- Explore 1345 Avenue of the Americas -->
    <div class="gallery-type-section text-centered" data-aos="fade-in">
      <!-- Header -->
      <div class="row">
        <div class="col-md-12">
          <h1>Explore @Ease <br>Hybrid &amp; Virtual Meetings</h1>
        </div>
      </div>

      <!-- First Block -->
      <div class="row gallery-block">
        <div class="col-md-7 full-width" data-aos="fade-right" data-aos-offset="600">
            <img src="@/assets/meetings-and-events-spaces/gallery/gallery10.png" />
        </div>
        <div class="col-md-5">
          <div class="Aligner">
            <div class="Aligner-item Aligner-item--top"></div>
            <div class="Aligner-item" data-aos="fade-left" data-aos-offset="600" data-aos-duration="800">
              <img src="@/assets/meetings-and-events-spaces/gallery/gallery13.png" />
            </div>
            <div class="Aligner-item Aligner-item--bottom"></div>
          </div>
        </div>
      </div>

      <!-- Second Block -->
      <div class="row gallery-block">
        <div class="col-md-7 overflow-bottom additional-padding" data-aos="fade-right" data-aos-offset="900" data-aos-duration="800">
          <img src="@/assets/meetings-and-events-spaces/gallery/gallery14.png" />
        </div>
        <div class="col-md-5 full-width" data-aos="fade-left" data-aos-offset="900" data-aos-duration="800">
          <img src="@/assets/meetings-and-events-spaces/gallery/gallery15.png" />
        </div>
      </div>

      <!-- Third Block -->
      <div class="row gallery-block">
        <div class="col-md-12 full-width extra-vertical-margin" data-aos="fade-down" data-aos-offset="800" data-aos-duration="900">
          <img src="@/assets/meetings-and-events-spaces/gallery/gallery9.png" />
        </div>
      </div>

      <!-- End of Gallery Section -->
    </div>

    <!-- Separator -->
    <img class="gradient-separator" src="@/assets/about-us/gradient-separator.png" />

    <!-- We'd Love To -->
    <div class="text-full-width text-centered contact-section" data-aos="fade-in" :ref="'contact-section'">
      <div class="Aligner">
        <div class="Aligner-item Aligner-item--top"></div>
        <div class="Aligner-item">
          <h1>We'd Love To<br>
          Hear From You</h1>
          <p>Please let us know how we can be of assistance.</p>
          <router-link to="/contact">Contact Us</router-link>
        </div>
        <div class="Aligner-item Aligner-item--bottom"></div>
      </div>
    </div>

    <div :class="{sticky: true, 'downloads-section': true, 'text-centered': true, hidden: !this.floatingDownloadsVisible }">
      <div class="col-md-12">
        <a class="generic-link" v-b-modal.rfp-modal>Request for Proposal</a>
<!--         <a class="generic-link" :href="`${publicPath}at_Ease_1345Sheet_One%20Sheet.pdf`" target="_blank">Download Capacity Chart</a> -->
        <a class="back-to-top-link" href="#"><img src="@/assets/down-arrow.svg" v-if="!isMobile()" /></a>
      </div>
    </div>

    <!-- RFP Modal Form -->
    <b-modal id="rfp-modal" content-class="rfp-modal" centered>
      <img class="close-button" src="@/assets/close.svg" @click="closeModal" />
      <div class="row">
        <div class="col-lg-6 address-info-container">
          <div class="contact-details">
            <h1>Request for proposal</h1>
            <h2 class="instr">Please complete the form to submit your inquiry.</h2>
            <p class="details">
              Ease Hospitality<br>
              299 Park Avenue<br>
              42nd Floor<br>
              New York, NY 10105
            </p>
            <p class="details">
              Phone: 212-201-0425<br>
              Email: info@easehospitality.com
            </p>
            <p class="follow">
              Follow Us
            </p>
            <div class="sns-icons">
                <a href="https://www.facebook.com/Ease-Hospitality-106482987830969" target="_blank" class="sns-logo"><img src="@/assets/contacts/facebook-dark-icon.svg" /></a>
                <a href="https://www.instagram.com/ease.hospitality" target="_blank" class="sns-logo"><img src="@/assets/contacts/instagram-dark-icon.svg" /></a>
                <a href="https://www.linkedin.com/company/easehospitality/" target="_blank" class="sns-logo"><img src="@/assets/contacts/linkedin-dark-icon.svg" /></a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 form-container">
          <div class="modal__col modal__col--right">
            <!-- Contact Form -->
            <form class="rfp-form" @submit="checkForm($event)" method="post">
              <h6>Contact Info</h6>
              <div class="form-group">
                <!-- First Name -->
                <input
                  type="text"
                  name="first_name"
                  placeholder="First Name *"
                  v-model="form.first_name"
                  required
                />

                <!-- Last Name -->
                <input
                  type="text"
                  name="last_name"
                  placeholder="Last Name *"
                  v-model="form.last_name"
                  required
                />
              </div>

              <div class="form-group">
                <!-- Email -->
                <input
                  type="email"
                  name="email"
                  placeholder="Email *"
                  v-model="form.email"
                  required
                />

                <!-- Phone -->
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone Number *"
                  v-model="form.phone"
                  required
                />
              </div>

              <div class="form-group">
                <!-- Company -->
                <input
                  type="text"
                  name="company"
                  placeholder="Company *"
                  v-model="form.company"
                  required
                />

                <!-- Job Title -->
                <input
                  type="text"
                  name="title"
                  placeholder="Job Title *"
                  v-model="form.title"
                  required
                />
              </div>

              <h6>Meeting Preference</h6>
              <!-- Reason for Contacting -->
              <div class="select">
                <select
                  name="event_type"
                  v-model="form.event_type"
                >
                  <option selected disabled>Select Event Type *</option>
                  <option value="meeting-event">Meetings &amp; Event</option>
                  <option value="meeting-hybrid">Hybrid In-Person</option>
                  <option value="meeting-other">Other</option>
                </select>
                <img src="@/assets/contacts/down-caret.svg" />
              </div>

              <!-- Location dropdown -->
              <div class="select">
                <select
                  name="location"
                  v-model="form.location"
                >
                  <option disabled>Location</option>
                  <option>1345</option>
                  <option>605</option>
<!--                   <option selected>Virtual</option> -->
                </select>
                <img src="@/assets/contacts/down-caret.svg" />
              </div>

              <div class="form-group">
                <!-- Number of Attendees -->
                <input
                  type="number"
                  name="guest"
                  placeholder="Number of Attendees *"
                  v-model="form.guest"
                  required
                />

                <!-- Desired Date -->
                <input
                  type="date"
                  name="event_date"
                  placeholder="Desired Date *"
                  v-model="form.event_date"
                  required
                />
              </div>

              <!-- Message -->
              <textarea rows="4" placeholder="Message" name="event_requirements" v-model="form.event_requirements">
              </textarea>

              <div class="checkbox-container">
                <input type="checkbox" v-model="form.agreement" /> <label>I accept the <a href="#">terms of agreement</a>.</label>
                <button type="submit">SUBMIT</button>
              </div>

              <!-- End of Contact Form -->
            </form>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Video Player modal -->
    <b-modal id="video-modal" content-class="video-modal" centered>
      <div class="app-preview-video">
        <iframe width="560" height="315" :src="youtubeembedurl" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <img class="close-button" src="@/assets/close.svg" @click="closeVideoModal" />
    </b-modal>
    <loading :active.sync="isLoading"
    :is-full-page="fullPage"></loading>
  </div>
</template>

<style lang="scss">
@import '@/styles/page-body.scss';
/* #hybrid-body .image-content img  {
  height: 750px !important;
} */
#hybrid-body{

  .top-section{
    padding-top: 20px;
    padding-bottom: 150px;
  }
  .app-preview{
    .row.header{
      width: 50%;
      margin: 0 auto;
      h1{
        margin-top: 70px;
        margin-bottom: 20px;
        font-size: 42px;
      }
      p{
        font-size: 21px;
      }
    }
    div.download-buttons{
      a{
        margin-right: 20px;
      }
    }
    .image-container{
      position: relative;
    }
    img.app-preview-image{
      position: absolute;
      top: -100%;
      left: -100%;
      bottom: -100%;
      right: -100%;
      margin: auto;

    }

    .app-preview-container{
      margin-top: 100px;
      margin-bottom: 135px;
      .row.app-label{
        margin: 100px 0;
        p{
          padding: 0 15%;
          font-size: 21px;
        }
      }
    }
  }
  .the-space-row{
    padding: 120px 0 60px 0;
  }
  .fabric-bg.overflow-horizontal{
    background: $black;
    // background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(~@/assets/luxury-amenities/fabric-bg.png);
    // background-repeat: no-repeat !important;
    // background-size: cover !important;
    div.text-content{
      padding: 0 !important;
    }
    p{
      font-size: 21px;
      &.italicized-text{
        font-size: 14px !important;
        font-style: italic;
        margin-bottom: 50px;
      }
    }
    .Aligner-item{
      margin-left: -20%;
    }

    .features-title {
      font-size: 32px;
      color: $gold !important;
    }
    .additional-feature{
      margin-bottom: 50px;
      padding-bottom: 100px;
    }

    .image-content{
      position: relative;
      cursor: pointer;
      .play-button{
        position: absolute;
        width: auto;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        text-align: center;
        box-shadow: none;
      }
    }
  }

  .marble-bg{
    background: url(~@/assets/luxury-amenities/marble-bg.png);
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }

  .experiences-section{
    background: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url(~@/assets/meetings-and-events-spaces/events-experiences/events1.png);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 250px 25% !important;
    margin-bottom: 115px;
    h1{
      font-size: 42px;
    }
    p{
      font-size: 22px;
    }
  }

  .technology-section{
    padding:100px 0 !important;
  }

  .downloads-section{
    padding: 90px 0;
  }

  .sticky.downloads-section {
    padding: 30px 0;
    box-shadow: 0 -2px 8px rgb(0,0,0, .25);
    border-top: 4px solid #C28A49;
    padding: 30px 0;
    background: rgba(#F2EDE6, .75);
    backdrop-filter: blur(10px);
  }

  .tiers-carousel{
    padding-top: 50px !important;
    max-width: 1440px;
    margin: 0 auto;
    h1 {
      font-size: 2rem;
    }
    ul{
      padding-left: 20px;
      padding-top: 16px;
      li{
        margin-bottom: 24px;
      }
    }
    .image-content img  {
      height: 750px !important;
    }
  }
  .sticky {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 24px 0;
    background:rgba($ivory, .85);
    box-shadow: 0 -1px 4px rgba($black, .25);
    border-top: 1px solid $gold;
    backdrop-filter: blur(8px);

    &.hidden{
      display: none;
    }
  }

  /** Ipad */
  @media only screen and (min-width: 1024px) and (max-width: 1156px) {
    .the-space-row{
      .image-content{
        padding-right: 50px !important;
      }
      h1{
        font-size: 35px !important;
      }
      p{
        font-size: 16px !important;
      }
    }

    .line-separated-list{
      li{
        font-size: 16px !important;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
  /** Mobile */
  @media only screen and (max-width: 1023px) {

    .the-space-row{
      padding: 0px 0 40px 0;
      .image-content{
        padding-bottom: 50px;
      }
    }

    .technology-section{
      padding:0 !important;
      .image-content{
        padding-bottom: 50px;
      }
    }

    .downloads-section{
      padding-bottom: 50px;
    }
    .experiences-section{
      padding: 40px 0 !important;
      .header {
        margin: 0;
      }
    }
    .row.header{
      width: 100% !important;
      h1{
        font-size: 35px !important;
      }
      p{
        font-size: 16px !important;
      }
    }

    .row.app-label{
      margin: 0 !important;
    }
    .image-container{
      position: static !important;
    }
    img.app-preview-image{
      margin: auto;
      width: 100%;
      max-width: 608px;
      position: static !important;

    }
    .fabric-bg.overflow-horizontal{
      .text-content{
        width: 100% !important;
      }
      .Aligner-item{
        margin: 0 auto;
      }
    }


    .tiers-carousel{
      .text-content{
        width: 100% !important;
        .generic-link{
          text-align: center;
        }
      }
    }

    .gallery-type-section {
      h1{
        font-size: 28px !important;
      }
      p{
        margin: 0 20%;
      }
      img{
        padding-top: 20px;
      }
      .extra-vertical-margin{
        margin: 40px 0;
        &.bottom{
          margin-bottom: 0;
        }
      }
    }
    /** End mobile */
  }
      .image-content{
        position: relative;
        cursor: pointer;
        .play-button{
          position: absolute;
          width: auto !important;
          margin: auto;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          text-align: center;
          box-shadow: none;
        }
      }
}


#rfp-modal{
  .modal-dialog{
    max-width: 1140px;
  }
  .contact-details{
    h1{
      font-size: 2.5rem;
      line-height: 2.625rem;
    }
    h2.instr{
      font-size: 16px;
      margin-bottom: 32px;
    }
    p.details{
      font-size: 20px;
      margin-bottom: 32px;
    }
    p.follow{
      font-size: 24px;
    }

    .sns-icons{
      display: inline-block;
      margin: 0 auto;
      .sns-logo + .sns-logo{
          margin-left: 18px;
      }
    }
  }
  .rfp-modal{
    position: relative;
    .close-button{
      position: absolute;
      top: -12%;
      right: -7%;
    }
    padding: 30px;
    background-color: $ivory;
    border-radius: 0;
    border-width: 0;
    .modal-header{
      display: none;
    }
    .modal-body{
      padding: 0;
      color: $palm-green;
      .image-content{
        .VueCarousel{
          height: 100%;

          img{
            object-fit: cover;
            width: 100%;
          }
        }
      }
      .text-content{
        padding: 0 8%;
        p.desc{
          font-size: 21px;
        }
        p.email{
          font-size: 16px;
          text-transform: uppercase;
          margin-bottom: 0;
        }
      }
    }
    .sns-logo-containers{
      margin-top: 15px;
    }
    .modal-footer{
      display: none;
    }
    /** Mobile */
    @media only screen and (max-width: 1248px) {
      .close-button{
        z-index: 999;
        top: 0 !important;
        right: 0 !important;
      }
    }
  }
  /** Mobile */
  @media only screen and (max-width: 1023px) {
    .modal-body{
      .text-content{
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        h1{
          font-size: 30px !important;
          margin-bottom: 10px !important;
        }
        p.desc{
          font-size: 16px !important;
        }
        p.email{
          font-size: 16px !important;
        }
      }
    }
    /** End of Mobile */
  }
}


.rfp-form{
  h6{
    font-size: 16px;
    font-weight: 600;
  }
  .form-group{
    display: flex;
    margin-bottom: 0;

    input + input{
      margin-left: 20px;
    }
  }
  .checkbox-container{
    margin-top: 33px;
    input{
      display:inline-block;
      width: auto;
      margin-right: 10px;
    }
    label{
      display: inline-block;
      color: $palm-green;
      a{
        color: $palm-green;
        text-decoration: underline;
      }
    }
  }
  input, textarea, select{
    display: block;
    width: 100%;
    font-size: 16px;
    justify-content: space-between;
  }

  input{
    background: transparent;
    border: 0;
    border-bottom: 1px solid $palm-green;
    margin-bottom: 33px;
    outline: none;

    &::placeholder{
      color: $palm-green;
    }
  }

  .select{
    position: relative;
    img{
      position: absolute;
      right: 0;
      bottom: 30%;
      height: 9.5px;
      width: 18px;
      object-fit: cover;
    }
  }
  select{
    background: transparent;
    border: 0px transparent solid;
    border-bottom: 1px solid $palm-green;
    margin-bottom: 33px;
    outline: none;
    color: $palm-green;

    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
  }

  textarea{
    background: transparent;
    padding: 20px;
    border-bottom: 1px solid $palm-green;
  }

  input:focus, textarea:focus, button:focus, select:focus{
    outline: none;
  }

  button{
    display: block;
    background: transparent;
    border: 0;
    border-bottom: 2px solid #EEC6BB;
    font-size: 18px;
    color: $palm-green;
    letter-spacing: 0.2em;
    margin-top: 20px;
  }
}

#video-modal{
  .video-modal{
    background-color: black;
  }
  .modal-header{
    display: none;
  }
  .modal-footer{
    display: none;
  }
  .modal-dialog{
    max-width: 600px;
  }

  video{
    width: 1024px;
    max-width: 100%;
  }
}
</style>

<script>
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

import {RSVPForm} from "@/models/RSVPForm";
import {API} from "@/API";
export default {
  name: 'MeetingsEventsSpaceBody',
  data() {
    return{
      currentTierPage: 0,
      floatingDownloadsVisible: false,
      youtubeembedurl: null,
      isLoading: false,
      fullPage: true,
      form: RSVPForm.blank('Virtual'),
    }
  },
  components: {
    Loading,
  },
  created () {
    window.addEventListener('scroll', this.meetingsDidScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.meetingsDidScroll);
  },
  methods: {
    goToTierPage(pageIdx){
      this.currentTierPage = pageIdx
    },
    handlePageChange(page){
      this.currentTierPage = page
    },
    meetingsDidScroll(){
      // console.log(e.target)
      var diff = (this.$refs['contact-section'].getBoundingClientRect().bottom - window.innerHeight)
      this.floatingDownloadsVisible = window.scrollY > 100 && diff > -150
    },
    closeModal(){
      this.$bvModal.hide('rfp-modal')
      this.currentMemberIdx = 0
    },
    closeVideoModal(){
      this.$bvModal.hide('video-modal')
      this.currentMemberIdx = 0
    },
    openVideoModal(url){
      this.youtubeembedurl = url
    },
    checkForm(event){
      var $this = this
      event.preventDefault()

      if($this.form.agreement == false){
          Swal.fire(
            'Oops...',
            'You must accept the terms of agreement.',
            'info'
          )
        return;
      }

      $this.isLoading = true;
      // console.log("RSVP Form:")
      // console.log(this.form)
      API.submitRSVP(this.form)
        .then(function(/* response */){
          $this.isLoading = false
          // console.log(response)

          $this.form = RSVPForm.blank('Virtual'),

          Swal.fire(
            'Thank You!',
            'Your inquiry has been sent.',
            'success'
          )
        })
        .catch(function (/* error */) {
          $this.isLoading = false
          // console.log(error)
          // handle error
          Swal.fire(
            'Oops...',
            'Something went wrong!',
            'error'
          )
        })

    },
  }
}
</script>
