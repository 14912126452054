<template>
  <div id="luxury-amenities">
    <!-- @Ease App -->
    <div class="row app-preview center-aligned marble-bg">
      <div class="col-lg-12">
        <!-- Header -->
        <div class="row header">
          <div class="col-lg-12">
            <h1>@Ease App</h1>
            <!-- <div class="download-buttons">
              <a href="https://apps.apple.com/kh/app/ease-workplace/id1526255927" target="_blank"><img src="@/assets/luxury-amenities/app-store-transparent.svg" /></a>
              <a href="https://play.google.com/store/apps/details?id=com.risesoftware.atease&hl=en_US&gl=US" target="_blank"><img src="@/assets/luxury-amenities/play-store-transparent.svg" /></a>
            </div> -->
          </div>
        </div>
        <!-- App and Labels -->
        <div class="row app-preview-container">
          <!-- Left Labels -->
          <div class="col-lg-4">
            <!-- Meeting Rooms -->
            <div class="row app-label">
              <div class="col-lg-12">
                <h2>Meeting Rooms</h2>
                <p>Reserve meeting rooms, quickly and conveniently.</p>
              </div>
            </div>
            <!-- Food & Beverage -->
            <div class="row app-label">
              <div class="col-lg-12">
                <h2>Food &amp; Beverage</h2>
                <p>Order Grab n Go meals and coffees from the cafe.</p>
              </div>
            </div>
            <!-- Fitness & Wellness -->
            <div class="row app-label">
              <div class="col-lg-12">
                <h2>Fitness &amp; Wellness</h2>
                <p>Reserve a place in our membership-based fitness and wellness classes.</p>
              </div>
            </div>
          </div>
          <!-- Middle Image -->
          <div class="col-lg-4 image-container">
            <div class="app-mobile-container">
<!--               <video class="app-preview-video" preload autoplay loop>
                <source src="@/assets/luxury-amenities/iphone-preview.mp4" type="video/mp4">
                <source src="@/assets/luxury-amenities/iphone-preview.webm" type="video/webm">
                Your browser does not support the video tag.
              </video> -->
               <img class="app-preview-image" src="@/assets/luxury-amenities/iphone-11-frame.svg" />
            </div>

            <!--
            <img class="app-preview-image" src="@/assets/luxury-amenities/ease-app-preview.png" /> -->
          </div>
          <!-- Right Labels -->
          <div class="col-lg-4">
            <!-- Massage Reservations -->
            <div class="row app-label">
              <div class="col-lg-12">
                <h2>Massage Reservations</h2>
                <p>Schedule a restorative massage with one of our licensed professionals.</p>
              </div>
            </div>
            <!-- Building Access -->
            <div class="row app-label">
              <div class="col-lg-12">
                <h2>Building Access</h2>
                <p>Integrate with the building access and visitor management system.</p>
              </div>
            </div>
            <!-- Vertical Catering -->
            <div class="row app-label">
              <div class="col-lg-12">
                <h2>Vertical Catering</h2>
                <p>Arrange for vertical catering, directly to your floor.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/styles/page-body.scss';
#luxury-amenities{
  .app-preview{
    color: $palm-green;
    .row.header{
      width: 50%;
      margin: 0 auto;
      h1{
        margin-top: 40px;
        margin-bottom: 20px;
        font-size: 42px;
      }
      p{
        font-size: 21px;
      }
    }
    div.download-buttons{
      a{
        margin-right: 20px;
      }
    }
    .image-container{
      position: relative;
      min-height: 967px;
    }
    .app-mobile-container{
      margin: 0 auto;
      width: 495px;
      position: absolute;
      left: -100%;
      right: -100%;

      img.app-preview-image{
        width: 100%;
      }
      .app-preview-video{
        position: absolute;
        top: 75px;
        width: 378px;
        left: 59px;
        border-radius: 32px;
      }
    }

    .app-preview-container{
      margin-top: 0;
      margin-bottom: 0;
      .row.app-label{
        margin: 100px 0;
        p{
          padding: 0 15%;
          font-size: 21px;
        }
      }
    }
  }

  .amenities-container{
    .text-container{
      margin-top: 50px;
      // height: 65%;
    }
  }

  .fabric-bg{
    background: $black;
    // background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(~@/assets/luxury-amenities/fabric-bg.png);
    // background-repeat: no-repeat !important;
    // background-size: cover !important;
    div.text-content{
      padding-right: 9% !important;
      padding-left: 9% !important;
      width: 100% !important;
        .Aligner{
          padding-bottom: 100px;
        }
    }
    p{
      font-size: 21px;;
    }
  }

  .marble-bg{
    background: url(~@/assets/luxury-amenities/marble-bg.png);
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }

  /** Slide Transition */
  .VueCarousel{
    &.img-wrapper{
      width: 99%;
    }
  }
  .bullets{
    position: absolute;
    bottom: 5%;
    right: 0;
    width: 30% !important;
    margin-right: 10%;
    margin-left: 65%;
    p{
      font-size: 16px;
      line-height: 20px;
    }
  }
  .ameneties-carousel{
    margin: -40px 0;
  }
  .row.expanded-row{
    margin-bottom: 100px;
  }

  /** Ipad */
  @media only screen and (min-width: 767px) and (max-width: 1490px) {
    .ameneties-carousel{
      .text-content{
        h1{
          font-size:30px !important;
        }
        p{
          font-size: 18px !important;
        }
      }
    }
  }

  /** Ipad */
  @media only screen and (min-width: 1024px) and (max-width: 1156px) {
    .bullets p{
      font-size: 16px;
      line-height: 20px;
      padding: 0 10px;
    }
  }

  /** Mobile */
  @media only screen and (max-width: 1023px) {
    .app-preview{
      color: $palm-green;
      padding-bottom: 0px;
    }
    .row.header{
      width: 100% !important;
      h1{
        font-size: 35px !important;
      }
      p{
        font-size: 16px !important;
      }
    }

    .bullets{
      position: relative;
      margin: 100px auto 50px auto;
      width: 80% !important;
    }
    .row.app-label{
      margin: 0 !important;
    }
    .image-container{
      position: static !important;
    }
    .text-content{
      .Aligner{
        padding-bottom: 0 !important;
      }
    }
  }

  @media only screen and (max-width: 991px){
    .image-container{
      min-height: 781px !important;
    }
    .app-mobile-container{
      position: relative !important;
      left: 0 !important;
      right: 0 !important;
    }
  }

  @media only screen and (max-width: 491px){
    .image-container{
      min-height: 586px !important;
    }
    .app-mobile-container{
      width: 400px !important;
      .app-preview-video{
        width: 305px !important;
        top: 116px !important;
        left: 48px !important;
      }
    }
  }

  @media only screen and (max-width: 398px){
    .app-mobile-container{
      width: 300px !important;
      .app-preview-video{
          width: 230px !important;
          top: 86px !important;
          left: 35px !important;
      }
    }
  }
}
</style>

<script>
export default {
  name: 'MobileAppStandalonePage',
  data() {
    return{
      currentAmenityIdx: 0,
      amenities: [
      ]
    }
  },
  methods: {

  }
}
</script>
