<template>
  <div class="event-individual">
    <!-- Blog Post Body -->
    <div class="event-page-item">
      <div v-if="this.post == null">
        <h1>Loading</h1>
      </div>
      <div v-if="this.post != null">
        <div class="banner" :style="bannerCss">
          <NavigationBar />
          <div class="banner-content" data-aos="fade-in">
            <router-link to="/"><img class="ease-logo" src="@/assets/ease-logo.svg" /></router-link>
            <h1>{{ this.post.title }}</h1>
            <!-- <p>As featured in Architectural Digest</p> -->
          </div>
          <div class="scroll-to-bottom-container">
            <!-- <img src="@/assets/down-arrow.svg" /> -->
          </div>
        </div>
        <div class="post-content" v-html="this.post.body">
        </div>
      </div>
    </div>

    <loading :active.sync="isLoading"
             :is-full-page="true"></loading>

    <!-- Pagination -->
    <div class="pagination-section" :hidden="!this.hasPagination">
      <div class="row">
        <div class="col-md-4 previous-button pagination-column">
          <a :class="{'invisible': this.previousPostUrl == null}" :href="this.previousPostUrl" >
            <img src="@/assets/left-arrow.svg" />
            <p>PREV</p>
          </a>
        </div>
        <div class="col-md-4 pagination-column">
          <a href="/events"><img class="logo-gfx" src="@/assets/about-us/seamless.svg" /></a>
        </div>
        <div class="col-md-4 next-button pagination-column">
          <a :class="{'invisible': this.nextPostUrl == null}" :href="this.nextPostUrl">
            <p>NEXT</p>
            <img src="@/assets/right-arrow.svg" />
          </a>
        </div>
      </div>
    </div>

    <!-- Separator -->
    <img class="gradient-separator" src="@/assets/about-us/gradient-separator.png" />

    <!-- We'd Love To -->
    <div class="text-full-width text-centered contact-section" data-aos="fade-in">
      <div class="Aligner">
        <div class="Aligner-item Aligner-item--top"></div>
        <div class="Aligner-item">
          <h1>We'd Love To<br>
            Hear From You</h1>
          <p>Please let us know how we can be of assistance.</p>
          <router-link to="/contact">Contact Us</router-link>
        </div>
        <div class="Aligner-item Aligner-item--bottom"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '../styles/banner.scss';
@import "../styles/contact.scss";
@import "../styles/blog/event-page-item.scss";

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script>
import NavigationBar from '@/components/NavigationBar.vue'
import {API} from "@/API";
import Loading from "vue-loading-overlay";
import encodeurl from "encodeurl"

export default {
  name: 'BlogPage',
  components: {
    NavigationBar,
    Loading
  },
  data: function() {
    return {
      isLoading: false,
      post: null,
      previousPostUrl: null,
      nextPostUrl: null,
      hasPagination: false,
    }
  },
  methods: {
    fetchPost() {
      if(this.$route.path.includes("/events/")){
        const event_id = this.$route.params.event_id
        const $this = this;
        $this.isLoading = true
        API.getEventPost(event_id)
            .then(function (response){
              const result = response.data
              $this.post = result.post
              const baseurl = window.location.origin + "/events/"
              $this.hasPagination = false;
              if(result.previous != null) {
                $this.previousPostUrl = baseurl + result.previous
                $this.hasPagination = true;
              }
              if(result.next != null) {
                $this.nextPostUrl = baseurl + result.next
                $this.hasPagination = true;
              }
              $this.isLoading = false
            })
      }else{
        this.hasPagination = false;
        this.post = this.$route.query;
      }
    }
  },
  mounted: function(){
    this.fetchPost()
  },
  computed: {
    bannerCss() {
      const bannerURL = encodeurl(this.post.banner_url);
      if (this.post == null || this.post.banner_url == null) {
        return {
          "background": `linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url(~@/assets/banners/605-bg.jpg)`
        }
      }
      return {
        "background": `linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url(${bannerURL})`
      }
    }
  }
}
</script>

<style scoped>

</style>