<template>
  <div class="luxury-amenities">
    <LuxuryAmenitiesBanner />
    <LuxuryAmenitiesBody />
  </div>
</template>

<script>
import LuxuryAmenitiesBanner from "@/components/luxury-amenities/Banner.vue";
import LuxuryAmenitiesBody from "@/components/luxury-amenities/Body.vue";

export default {
  name: 'LuxuryAmenities',
  components: {
    LuxuryAmenitiesBanner,
    LuxuryAmenitiesBody
  }
}
</script>
