<template>
  <div id="app">
    <router-view></router-view>
    <Footer v-if="hasFooter()" />
    <SideMenuOverlay />
  </div>
</template>

<script>
import SideMenuOverlay from '@/components/SideMenuOverlay.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: {
    SideMenuOverlay,
    Footer
  },
  data() {
    return {
      menu: [
          {
              href: '/',
              title: 'Home'
          },
          {
              href: '/about-us',
              title: 'About Us'
          },
          {
              href: '/locations',
              title: 'Locations'
          },
          {
              href: '/meetings-and-events-spaces',
              title: 'Meetings and Events Spaces'
          },
          {
              href: '/luxury-amenities',
              title: 'Luxury Amenities'
          },
          {
              href: '/press',
              title: 'Press'
          },
          {
            href: '/blog',
            title: 'Blog'
          },
          {
              href: '/contact',
              title: 'Contact'
          },
      ]
    }
  },
  methods: {
    hasFooter(){
      return this.$route.name != "Mobile App"
    }
  }
}
</script>
<style lang="scss">
@import "styles/global.scss";
.ease-logo{
  display: block;
  width:100px;
  margin: 20px auto;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
