<template>
  <div id="blog">
    <!-- News Container -->
    <div class="news-container">
      <!-- Our Story -->
      <div v-for="(post, index) in this.posts"
           :key="`news-${index}`"
           class="row text-image news-item"
           :class="{'left-aligned': index % 2 == 0,
                    'right-aligned': index % 2 != 0}"
           data-aos="fade-in"
           data-aos-offset="200">
        <div class="col-md-6 image-content"
             :class="{'order-md-2': index % 2 == 0,
                    'order-md-1': index % 2 != 0}">
          <div class="image-wrapper">
            <img :src="post.image" />
          </div>
        </div>
        <div class="col-md-6 text-content"
             :class="{'order-md-1': index % 2 == 0,
                    'order-md-2': index % 2 != 0}">
          <div class="Aligner">
            <div class="Aligner-item Aligner-item--top"></div>
            <div class="Aligner-item">
              <h1>{{post.title}}</h1>
              <p>{{post.desc}}</p>
              <div class="news-footer">
                <p>{{post.date_posted}} | {{post.author}}</p>
                <a :href="post.link">Read More</a>
              </div>
            </div>
            <div class="Aligner-item Aligner-item--bottom"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Pagination -->
    <div class="pagination-section" :hidden="!this.hasPagination">
      <div class="row">
        <div class="col-md-5 previous-button pagination-column">
          <a :hidden="this.previousPageUrl == null" :href="this.previousPageUrl" >
            <p>NEWER POSTS</p>
          </a>
        </div>
        <div class="col-md-2 pagination-column">
          <a href="/"><img class="logo-gfx" src="@/assets/about-us/seamless.svg" /></a>
        </div>
        <div class="col-md-5 next-button pagination-column">
          <a :hidden="this.nextPageUrl == null" :href="this.nextPageUrl">
            <p>OLDER POSTS</p>
          </a>
        </div>
      </div>
    </div>

    <loading :active.sync="isLoading"
             :is-full-page="true"></loading>
  </div>
</template>

<style lang="scss">
@import '../../styles/page-body.scss';
@import "../../styles/blog/news-press-list.scss";
#blog{
  .news-item{
    .image-content{
      .image-wrapper{
        background: #C4C4C4;
        /* height: 500px; */
      }
      img{
        height: 500px;
        &[src=""] {
          visibility: hidden;
        }
      }
    }

    .text-content{
      .news-footer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        p{
          font-size: 18px;
          line-height: 26px;
        }
        a{
          margin-bottom: 16px;
          font-weight: bold;
          font-size: 18px;
          letter-spacing: 0.2em;
          text-transform: uppercase;
          color: $palm-green;
          border-bottom: 2px solid #EEC6BB;
        }
      }
    }
  }

  /** Mobile */
  @media only screen and (max-width: 1023px) {
    .text-content{
      .news-footer{
        display: block !important;
        margin: 0 auto;
        p{
          display: block !important;
        }
        a{
          display: block !important;
          text-align: center;
          margin: 0 25%;
        }
      }
    }
  }
}
</style>

<script>
import 'vue-loading-overlay/dist/vue-loading.css';
import Loading from 'vue-loading-overlay';
import {API} from "@/API";
import {BlogListItemViewModel} from "@/models/BlogListItemViewModel";
import {PostType} from "@/enums/PostType";

export default {
  name: 'BlogList',
  components: {
    Loading
  },
  data: function(){
    return {
      isLoading: false,
      page: 1,
      posts: [],
      previousPageUrl: null,
      nextPageUrl: null,
      hasPagination: false,
    }
  },
  methods: {
    fetchBlog(){
      const $this = this;
      $this.isLoading = true
      API.getPosts(PostType.blog, this.page)
      .then(function (response){
        const result = response.data
        console.log(`Result: ${JSON.stringify(result)}`)
        $this.posts = result.data.map(function (post){
          return new BlogListItemViewModel(post);
        });
        $this.isLoading = false
        $this.hasPagination = result.last_page > 1

        $this.previousPageUrl = null;
        $this.nextPageUrl = null;
        if($this.hasPagination) {
          const base_url = `${window.location.origin}/blog`
          if(result.current_page > 1) {
            $this.previousPageUrl = `${base_url}?page=${result.current_page - 1}`
          }
          if(result.current_page < result.last_page) {
            $this.nextPageUrl = `${base_url}?page=${result.current_page + 1}`
          }
        }
      })
    },
  },
  mounted: function(){
    if(this.$route.query.page){
      this.page = this.$route.query.page
    }
    console.log(this.page)
    this.fetchBlog()
  },
}
</script>