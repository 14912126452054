export class ContactForm {
    constructor(name, email, phone, reason, location, message, source) {
        this.name = name
        this.email = email
        this.phone = phone
        this.reason = reason
        this.location = location
        this.message = message
        this.source = source
    }

    static blank() {
        return new ContactForm('','','','','','','')
    }
}