<template>
  <div id="meetings-body">

    <!-- Video -->
    <div class="gallery-type-section text-centered" data-aos="fade-in">
      <!-- First Vid -->
      <div class="row gallery-block">

        <div class="col-md-6">
          <h5 class="video-title">Tenant Lounge</h5>
          <video class="space-preview-video"  preload autoplay loop controls muted poster="@/assets/locations/Poster_605CommonSpace.png">
            <source src="@/assets/locations/605CommonSpace.mp4" type="video/mp4">
            <source src="@/assets/locations/605CommonSpace.webm" type="video/webm">
            Your browser does not support the video tag.
          </video>
        </div>
        <!-- Second Vid -->
        <div class="col-md-6" data-aos="fade-left" data-aos-offset="200">
            <h5 class="video-title">Conference Center</h5>
            <video class="space-preview-video"  preload autoplay loop controls muted poster="@/assets/locations/Poster_605ConferenceSpace.png">
              <source src="@/assets/locations/605ConferenceSpace.mp4" type="video/mp4">
              <source src="@/assets/locations/605ConferenceSpace.webm" type="video/webm">
              Your browser does not support the video tag.
            </video>
        </div>
      </div>
    </div>

    <!-- Meetings &amp; Events -->
    <div class="row text-image right-aligned dark-bg fabric-bg overflow-image overflow-horizontal">
      <div class="col-md-12">
        <div class="row the-space-row">
          <div class="col-md-8 image-content" data-aos="fade-right" data-aos-offset="200">
            <img src="@/assets/meetings-and-events-spaces/img-605-map.png" alt="605 Floorplan"/>
          </div>
          <div class="col-md-4 text-content" data-aos="fade-left" data-aos-offset="200">
            <div class="Aligner">
              <div class="Aligner-item Aligner-item--top"></div>
              <div class="Aligner-item">
                <h1>The Space</h1>
                <ul class="line-separated-list">
                  <li>4310 ft Dedicated Outdoor Space <br>with Private Seating Area</li>
                  <li>Two Separate Graze Refreshment <br>Kitchenette Service Bars, Fully Stocked</li>
                  <li>Event Capacity of up to 440 Guests* </li>
                  <li>Multi-Purpose Gallery </li>
                  <li>556 sq ft Garden Wall</li>
                  <li>LEED Gold Certified</li>
                </ul>
              </div>
              <div class="Aligner-item Aligner-item--bottom"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Technology -->
    <div class="row text-image left-aligned overflow-image overflow-horizontal technology-section">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-7 order-md-2 image-content" data-aos="fade-left" data-aos-offset="200">
            <img src="@/assets/meetings-and-events-spaces/605/tech2.png" alt="Technology Photo 2"/>
          </div>
          <div class="col-md-5 order-md-1 text-content" data-aos="fade-right" data-aos-offset="200">
            <div class="Aligner">
              <div class="Aligner-item Aligner-item--top"></div>
              <div class="Aligner-item">
                <h1>Technology</h1>
                <ul class="line-separated-list">
                  <li>130" Projection Screens, 4K UHD 10k Lumen Projectors</li>
                  <li>Complimentary High-Speed WiFi</li>
                  <li>Virtual and Hybrid Meeting Capabilities</li>
                  <li>Live Broadcast/Webinar Capabilities</li>
                  <li>Digital Branding Capabilities Throughout</li>
                  <li>Powered Meeting Tables</li>
                  <li>Onsite Technology Team</li>
                </ul>
              </div>
              <div class="Aligner-item Aligner-item--bottom"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- @Ease Meetings -->
    <div class="row text-image center-aligned experiences-section dark-bg">
      <div class="col-md-12 full-text text-content" data-aos="fade-in" data-aos-offset="200">
        <!-- Header -->
        <div class="row header">
          <div class="col-md-12">
            <h1>Ease Meetings<br>
              &amp; Events Experiences
            </h1>
            <p>Stay replenished and connected throughout your meetings with different tiers of our signature packages.</p>

<!--               <p>Onsite events can be scaled to accommodate intimate gatherings or large private in-person conferences, exhibits and events up to 400? Digital and Hybrid Events and enhancements, are only limited by physical capacities and imagination. These all-inclusive packages include standard to premier technology and a selection of seasonal food & beverage offerings allowing you to streamline your event planning process.</p>

              <p>Our flexible meeting space configurations include, but are not limited to classroom, collaboration conference or theater, and easily accommodate a wide variety of meetings and events. Moments of respite fill our philosophy of wellness and calm.</p> -->
          </div>
        </div>
      </div>
    </div>

    <!-- Tier 1 -->
    <div class="row text-image left-aligned tiers-carousel">
      <div class="col-md-12">
        <div class="container bullets">
          <div :class="{selected: this.currentTierPage == 0}" @click="goToTierPage(0)">
            <p>TIER I</p>
          </div>
          <div :class="{selected: this.currentTierPage == 1}" @click="goToTierPage(1)">
            <p>TIER II</p>
          </div>
          <div :class="{selected: this.currentTierPage == 2}" @click="goToTierPage(2)">
            <p>RECEPTION</p>
          </div>
        </div>
        <carousel
          :per-page=1
          @pageChange="handlePageChange"
          :value=currentTierPage
          :paginationEnabled=false>
          <!-- Tier 1 Meeting Package -->
          <slide>
            <div class="row">
              <div class="col-md-7 order-md-2 image-content" data-aos="fade-in" data-aos-offset="200">
                <img src="@/assets/meetings-and-events-spaces/tiers/tier1.png" alt="Tier 1 Photo"/>
              </div>
              <div class="col-md-5 order-md-1 text-content" data-aos="fade-in" data-aos-offset="200">
                <div class="Aligner">
                  <div class="Aligner-item Aligner-item--top"></div>
                  <div class="Aligner-item">
                    <h1>Tier 1</h1>
                    <ul class="regular-list">
                      <li>General Session Meeting Space</li>
                      <li>Dedicated Meal Space</li>
                      <li>Express Guest Check-In</li>
                      <li>Graze – All Day Refreshments</li>
                      <li>Continental Breakfast</li>
                      <li>Sandwich Lunch<br>
                        <em>*AM and/or PM Breaks available as add-ons*</em>
                      </li>
                     <li>On-Site Service Support</li>
                     <li>Pre-Conference Event Planning Support</li>
                     <li>Pre-Conference Technology Planning Support</li>
                     <li>Standard In-Room Technology</li>
                    </ul>
<!--                     <a class="generic-link" target="_blank" :href="`${publicPath}sample-menu-reception.pdf`">Download PDF</a> -->
                  </div>
                  <div class="Aligner-item Aligner-item--bottom"></div>
                </div>
              </div>
            </div>
          </slide>

          <!-- Tier 2 Meeting Package -->
          <slide>
            <div class="row">
              <div class="col-md-7 order-md-2 image-content" data-aos="fade-in" data-aos-offset="200">
                <img src="@/assets/meetings-and-events-spaces/tiers/tier2.png" alt="Tier 2 Photo"/>
              </div>
              <div class="col-md-5 order-md-1 text-content" data-aos="fade-in" data-aos-offset="200">
                <div class="Aligner">
                  <div class="Aligner-item Aligner-item--top"></div>
                  <div class="Aligner-item">
                    <h1>Tier II</h1>
                    <ul class="regular-list">
                      <li>General Session Meeting Space</li>
                      <li>Dedicated Meal Space</li>
                      <li>Express Guest Check-In</li>
                      <li>Graze – All Day Refreshments</li>
                      <li>Hot Buffet Breakfast</li>
                      <li>Hot Buffet Lunch<br>
                        <em>*AM and/or PM Breaks available as add-ons*</em>
                      </li>
                      <li>On-Site Service Support</li>
                      <li>Pre-Conference Event Planning Support</li>
                      <li>Pre-Conference Technology Planning Support</li>
                      <li>Standard In-Room Technology</li>
                    </ul>
<!--                     <a class="generic-link" target="_blank" :href="`${publicPath}sample-menu-half-day.pdf`">Download PDF</a> -->
                  </div>
                  <div class="Aligner-item Aligner-item--bottom"></div>
                </div>
              </div>
            </div>
          </slide>

          <!-- Tier 3 Slide Sample -->
          <slide>
            <div class="row">
              <div class="col-md-7 order-md-2 image-content" data-aos="fade-in" data-aos-offset="200">
                <img src="@/assets/meetings-and-events-spaces/tiers/tier3.png" alt="Tier 3 Photo"/>
              </div>
              <div class="col-md-5 order-md-1 text-content" data-aos="fade-in" data-aos-offset="200">
                <div class="Aligner">
                  <div class="Aligner-item Aligner-item--top"></div>
                  <div class="Aligner-item">
                    <h1>Reception</h1>
                    <ul class="regular-list">
                      <li>Dedicated Reception Space</li>
                      <li>Express Guest Check-In</li>
                      <li>Open Bar, Specialty Cocktails Available Upon Request</li>
                      <li>Passed Hors D'oeuvres, Appetizer Platters, Food Stations, and much more!</li>
                      <li>On-Site Service Support</li>
                      <li>Pre-Conference Event Planning Support</li>
                    </ul>
<!--                     <a class="generic-link" href="#">Download PDF</a> -->
                  </div>
                  <div class="Aligner-item Aligner-item--bottom"></div>
                </div>
              </div>
            </div>
          </slide>

          <!-- End of Carousel -->
        </carousel>
      </div>
    </div>

    <!-- Separator -->
    <img class="gradient-separator" src="@/assets/about-us/gradient-separator.png" alt="Divider"/>

    <!-- Explore 1345 Avenue of the Americas -->
    <div class="gallery-type-section text-centered" data-aos="fade-in">
      <!-- Header -->
      <div class="row">
        <div class="col-md-12">
          <h1>Explore 605 3rd Avenue</h1>
        </div>
      </div>

      <!-- First Block -->
      <div class="row gallery-block" style="padding-bottom: 80px;">
        <div class="col-md-6 full-width" data-aos="fade-right" data-aos-offset="200">
            <img src="@/assets/meetings-and-events-spaces/605/gallery1.png" alt="605 Gallery 1"/>
        </div>
        <div class="col-md-5 overflow-bottom additional-padding">
          <div class="Aligner">
            <div class="Aligner-item Aligner-item--top"></div>
            <div class="Aligner-item" data-aos="fade-left" data-aos-offset="200" data-aos-duration="200">
              <img src="@/assets/meetings-and-events-spaces/605/gallery2.png" alt="605 Gallery 2"/>
            </div>
            <div class="Aligner-item Aligner-item--bottom"></div>
          </div>
        </div>
      </div>

      <!-- Second Block -->
      <div class="row gallery-block">
        <div class="col-md-5 overflow-bottom" data-aos="fade-right" data-aos-offset="200">
          <img src="@/assets/meetings-and-events-spaces/605/gallery3.png" alt="605 Gallery 3"/>
        </div>
        <div class="col-md-7 full-width overflow-bottom" data-aos="fade-left" data-aos-offset="200" data-aos-duration="800">
          <img src="@/assets/meetings-and-events-spaces/605/gallery4.png" alt="605 Gallery 4"/>
        </div>
      </div>

      <!-- Third Block -->
      <div class="row gallery-block">
        <div class="col-md-12 full-width extra-vertical-margin" data-aos="fade-down" data-aos-offset="200">
          <img src="@/assets/meetings-and-events-spaces/605/gallery5.png" alt="605 Gallery 5"/>
        </div>
      </div>

      <!-- Fifth Block -->
      <div class="row gallery-block">
        <div class="col-md-7 full-width" data-aos="fade-right" data-aos-offset="200">
            <img src="@/assets/meetings-and-events-spaces/605/gallery9.png" alt="605 Gallery 9"/>
        </div>
        <div class="col-md-5">
          <div class="Aligner">
            <div class="Aligner-item Aligner-item--top"></div>
            <div class="Aligner-item" data-aos="fade-left" data-aos-offset="600">
              <img src="@/assets/meetings-and-events-spaces/605/gallery10.png" alt="605 Gallery 10"/>
            </div>
            <div class="Aligner-item Aligner-item--bottom"></div>
          </div>
        </div>
      </div>


      <!-- Third Block -->
      <div class="row gallery-block">
        <div class="col-md-12 full-width extra-vertical-margin" data-aos="fade-down" data-aos-offset="200">
          <img src="@/assets/meetings-and-events-spaces/605/gallery11.png" alt="605 Gallery 11"/>
        </div>
      </div>


      <!-- Fourth Block -->
      <div class="row gallery-block">
        <div class="col-md-5 overflow-bottom" data-aos="fade-left" data-aos-offset="200" data-aos-easing="ease-in-sine">
          <img src="@/assets/meetings-and-events-spaces/605/gallery6.png" alt="605 Gallery 6"/>
        </div>
        <div class="col-md-7" data-aos="fade-right" data-aos-offset="200">
          <img src="@/assets/meetings-and-events-spaces/605/gallery7.png" alt="605 Gallery 7"/>
        </div>
      </div>

      <!-- Sixth Block -->
      <div class="row gallery-block">
        <div class="col-md-12 full-width extra-vertical-margin bottom" data-aos-offset="200">
          <img src="@/assets/meetings-and-events-spaces/605/gallery8.png" alt="605 Gallery 8"/>
        </div>
      </div>

      <!-- End of Gallery Section -->
    </div>


  <!-- Map Block -->
    <div class="row map-spacing map-background" data-aos="fade-in" data-aos-offset="200">
      <div class="col-lg-12 full-width">
        <div class="row">
          <div class="col-lg-4 px-0 col-sm-12">
            <a href="https://goo.gl/maps/KkAuudGbSH4UfctDA" target="_blank"><img src="@/assets/meetings-and-events-spaces/605/map-605.png" class="fit-image" alt="605 Location Map"/></a>
          </div>
          <div class="col-lg-8 mb-4 pl-lg-5 col-sm-12">
            <div class="top-spacing map-fontstyle">
            <h2>Location</h2>
            <p>605 3rd Avenue is conveniently located in the heart of New York City </p>
            </div>
            <div class="row">
              <div class="col-lg-4 map-fontstyle col-sm-12">
                <h5><b>NEARBY PARKING</b></h5>
                <p>Icon Parking - 245 East 40th Street, New York, NY 10105</p>
                <h5><b>NEARBY CROSS STREET</b></h5>
                <p>Third Avenue, between 39th and 40th</p>
              </div>
              <div class="col-lg-4 map-fontstyle col-sm-12">
                <h5><b>NEARBY HOTEL</b></h5>
                <p><a class="map-link" target="_blank" href="https://www.ihg.com/spnd/hotels/us/en/new-york/nycpk/hoteldetail">70 Park Avenue Hotel</a><br/>70 Park Ave, NY 10016</p>
                <p><a class="map-link" target="_blank" href="https://www.affinia.com/shelburne-hotel-suites/">The Shelburne Sonesta</a><br/>303 Lexington Ave at 37<sup>th</sup> St, NY 10016</p>
                <p><a class="map-link" target="_blank" href="https://www.hyatt.com/en-US/hotel/new-york/hyatt-grand-central-new-york/nycgh">Hyatt Grand Central</a><br/>109 East 42<sup>nd</sup> St at Grand Central Terminal, NY 10017</p>
              </div>
              <div class="col-lg-4 map-fontstyle col-sm-12">
                <h5><b>DISTANCE FROM TRANSPORTATION</b></h5>
                <p>Port Authority Bus Terminal (1.2 miles)</p>
                <p class="mb-0">Penn Station (1.1 Miles)</p>
                <img src="@/assets/meetings-and-events-spaces/605/PennStation.png" alt="Penn Station Symbol"/>
                <p class="mb-0 mt-4">Grand Central Station (.4 Miles)</p>
                <img src="@/assets/meetings-and-events-spaces/605/GrandCentralStation.png" alt="Grand Central Station Symbol"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <!-- We'd Love To -->
    <div class="text-full-width text-centered contact-section" data-aos="fade-in" :ref="'contact-section'">
      <div class="Aligner">
        <div class="Aligner-item Aligner-item--top"></div>
        <div class="Aligner-item">
          <h1>We'd Love To<br>
          Hear From You</h1>
          <p>Please let us know how we can be of assistance.</p>
          <router-link to="/contact">Contact Us</router-link>
        </div>
        <div class="Aligner-item Aligner-item--bottom"></div>
      </div>
    </div>

    <div :class="{sticky: true, 'downloads-section': true, 'text-centered': true, hidden: !this.floatingDownloadsVisible }">
      <div class="col-md-12">
        <a class="generic-link" v-b-modal.rfp-modal>Request for Proposal</a>
        <a class="generic-link" :href="`${publicPath}Ease_605_One_Sheet.pdf`" target="_blank">Capacity Chart</a>
        <a class="back-to-top-link" href="#"><img src="@/assets/down-arrow.svg" v-if="!isMobile()" alt="Back to Top"/></a>
      </div>
    </div>

    <!-- RFP Modal Form -->
    <b-modal id="rfp-modal" content-class="rfp-modal" centered>
      <img class="close-button" src="@/assets/close.svg" @click="closeModal" alt="Close"/>
      <div class="row">
        <div class="col-lg-6 address-info-container">
          <div class="contact-details">
            <h1>Request for proposal</h1>
            <h2 class="instr">Please complete the form to submit your inquiry.</h2>
            <p class="details">
              Ease Hospitality<br>
              299 Park Avenue<br>
              42nd Floor<br>
              New York, NY 10105
            </p>
            <p class="details">
              Phone: 212-201-0425<br>
              Email: info@easehospitality.com
            </p>
            <p class="follow">
              Follow Us
            </p>
            <div class="sns-icons">
                <a href="https://www.facebook.com/Ease-Hospitality-106482987830969" target="_blank" class="sns-logo"><img src="@/assets/contacts/facebook-dark-icon.svg" alt="Facebook"/></a>
                <a href="https://www.instagram.com/ease.hospitality" target="_blank" class="sns-logo"><img src="@/assets/contacts/instagram-dark-icon.svg" alt="Instagram" /></a>
                <a href="https://www.linkedin.com/company/easehospitality/" target="_blank" class="sns-logo"><img src="@/assets/contacts/linkedin-dark-icon.svg" alt="LinkedIn" /></a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 form-container">
          <div class="modal__col modal__col--right">
            <!-- Contact Form -->
            <form class="rfp-form" @submit.prevent="checkForm($event)" method="post">
              <h6>Contact Info</h6>
              <div class="form-group">
                <!-- First Name -->
                <input
                  type="text"
                  aria-label="first_name"
                  name="first_name"
                  placeholder="First Name *"
                  v-model="form.first_name"
                  required
                />

                <!-- Last Name -->
                <input
                  type="text"
                  aria-label="last_name"
                  name="last_name"
                  placeholder="Last Name *"
                  v-model="form.last_name"
                  required
                />
              </div>

              <div class="form-group">
                <!-- Email -->
                <input
                  type="email"
                  aria-label="email"
                  name="email"
                  placeholder="Email *"
                  v-model="form.email"
                  required
                />

                <!-- Phone -->
                <input
                  type="text"
                  aria-label="phone"
                  name="phone"
                  placeholder="Phone Number *"
                  v-model="form.phone"
                  required
                />
              </div>

              <div class="form-group">
                <!-- Company -->
                <input
                  type="text"
                  aria-label="company"
                  name="company"
                  placeholder="Company *"
                  v-model="form.company"
                  required
                />

                <!-- Job Title -->
                <input
                  type="text"
                  aria-label="title"
                  name="title"
                  placeholder="Job Title *"
                  v-model="form.title"
                  required
                />
              </div>

              <h6>Meeting Preference</h6>
              <!-- Reason for Contacting -->
              <div class="select">
                <select
                  aria-label="event type"
                  name="event_type"
                  v-model="form.event_type"
                >
                  <option selected disabled>Select Event Type *</option>
                  <option value="meeting-event">Meetings &amp; Event</option>
                  <option value="meeting-hybrid">Hybrid In-Person</option>
                  <option value="meeting-other">Other</option>
                </select>
                <img src="@/assets/contacts/down-caret.svg" alt="dropdown"/>
              </div>

              <!-- Location dropdown -->
              <div class="select">
                <select
                  aria-label="location"
                  name="location"
                  v-model="form.location"
                >
                  <option disabled>Location</option>
                  <option>1345</option>
                  <option selected>605</option>
                  <option>No Preference</option>
                </select>
                <img src="@/assets/contacts/down-caret.svg" alt="dropdown"/>
              </div>

              <div class="form-group">
                <!-- Number of Attendees -->
                <input
                  type="number"
                  aria-label="guest"
                  name="guest"
                  placeholder="Number of Attendees *"
                  v-model="form.guest"
                  required
                />

                <!-- Desired Date -->
                <input
                  type="date"
                  aria-label="event date"
                  name="event_date"
                  placeholder="Desired Date *"
                  v-model="form.event_date"
                  required
                />
              </div>

              <!-- Message -->
              <textarea rows="4" aria-label="Message" placeholder="Message" name="event_requirements" v-model="form.event_requirements">
              </textarea>
              <br />
              <vue-recaptcha ref="recaptcha" @verify="onVerify"  @expired="onExpired"
               :sitekey="bookingForm.siteKey" :loadRecaptchaScript="true">
               </vue-recaptcha>

               <!-- <vue-recaptcha ref="recaptcha" @verify="onVerify"  @expired="onExpired"
                sitekey="6LcnIIkpAAAAAFlHu4JNmli5Bnrg3-HxbsvHNYoJ" :loadRecaptchaScript="true">
               </vue-recaptcha> -->

              <div><p style="color: red">{{ bookingForm.pleaseTickRecaptchaMessage }}</p></div>


              <div class="checkbox-container">
                <input type="checkbox" aria-label="terms" v-model="form.agreement" /> <label>I accept the <a href="#">terms of agreement</a>.</label>
                <button type="submit">SUBMIT</button>
              </div>

              <!-- End of Contact Form -->
            </form>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Welcome Modal -->
    <!-- <b-modal id="welcome-modal" content-class="welcome-modal" centered>
      <img class="close-button" src="@/assets/close.svg" @click="closeModalWelcome" />
      <div class="row">
        <div class="col-lg-12">
          <div class="contact-details">
            <h1>Welcome</h1>

              <p>The Ease Hospitality team is delighted to host you
                At @Ease 605. With hospitality at our core, we have designed
                this space with your work, needs and wellness in mind. This elevated and luxurious experience is here to add value to your day and provide a moment of ease. Biophilic design and seamless integration with world-class technology lead our product to offer you a space for productivity and wellbeing.</p>

                <p>Whether you're looking for a quiet space to take a call, a break from the office bustle or seeking a venue to host an event or conference, @Ease 605 has exactly what you need. We offer accommodation for any meeting type from private booths for virtual one-on-ones to conference rooms for large-scale tear chats. Need some fresh air following? Step outside to our expansive outdoor terrace areas. Hungry or need a pick-me- up? Enjoy delicious meals, fresh pastries and barista-crafted coffees at your convenience from the Palm Rose Cafe or place an order through our vertical catering via the @Ease Workplace app.</p>

                <p>Additionally, we hope you are able to enjoy the NFT exhibition, implemented in partnership with Blackdove, to allow Ease Hospitality to continue seamlessly blend the evolving physical and digital landscapes and offer authentic connectivity with the art and the digital artists.</p>

                <p>I want to personally thank you for making @Ease 605 your chosen workplace. If you need anything during your time here or would like to inquire about booking the space, please contact do not hesitate to contact us at info@easehospitality.com</p>

                <div  class="sig">
                  <img src="@/assets/meetings-and-events-spaces/605/welcome-signature.png" class="fit-image mb-2"/>
                  <p>Founder of Ease Hospitality</p>
                </div>
          </div>
        </div>
      </div>
    </b-modal> -->

    <loading :active.sync="isLoading"
    :is-full-page="fullPage"></loading>
  </div>
</template>

<style lang="scss">
@import '@/styles/page-body.scss';

#meetings-body{
  .map-link {
    color: #C28A49 !important;
    text-decoration: underline;

    &:after {
      content:'';
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-left: .25em;
      background: transparent url(~@/assets/right-arrow.svg) center 90% no-repeat;
      background-size: contain;
    }
  }
  .video-title {
    padding: 0 0 16px 0;
  }
  .space-preview-video {
    max-width: 100%;
  }

  .top-section {
    padding-top: 20px;
    padding-bottom: 150px;
  }
  .app-preview{
    .row.header{
      width: 50%;
      margin: 0 auto;
      h1{
        margin-top: 70px;
        margin-bottom: 20px;
        font-size: 42px;
      }
      p{
        font-size: 21px;
      }
    }

    div.download-buttons{
      a{
        margin-right: 20px;
      }
    }
    .image-container{
      position: relative;
    }
    img.app-preview-image{
      position: absolute;
      top: -100%;
      left: -100%;
      bottom: -100%;
      right: -100%;
      margin: auto;

    }

    .app-preview-container{
      margin-top: 100px;
      margin-bottom: 135px;
      .row.app-label{
        margin: 100px 0;
        p{
          padding: 0 15%;
          font-size: 21px;
        }
      }
    }
  }
  .the-space-row{
    padding: 120px 0 60px 0;
  }
  .fabric-bg.overflow-horizontal{
    background: $black;
    // background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(~@/assets/luxury-amenities/fabric-bg.png);
    // background-repeat: no-repeat !important;
    // background-size: cover !important;
    div.text-content{
      padding: 0 !important;
    }
    p{
      font-size: 21px;
      &.italicized-text{
        font-size: 14px !important;
        font-style: italic;
        margin-bottom: 50px;
      }
    }
    .Aligner-item{
      margin-left: -20%;
    }
  }

  .marble-bg{
    background: url(~@/assets/luxury-amenities/marble-bg.png);
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }

  .experiences-section{
    background: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url(~@/assets/meetings-and-events-spaces/605/tech1.png);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 250px 25% !important;
    margin-bottom: 115px;
    h1{
      font-size: 42px;
    }
    p{
      font-size: 22px;
    }
  }

  .technology-section{
    padding:100px 0 !important;
  }

  .downloads-section{
    padding: 90px 0;
  }

  .sticky.downloads-section {
    padding: 30px 0;
    box-shadow: 0 -2px 8px rgb(0,0,0, .25);
    border-top: 4px solid #C28A49;
    padding: 30px 0;
    background: rgba(#F2EDE6, .75);
    backdrop-filter: blur(10px);
  }

  .tiers-carousel{
    max-width: 1440px;
    margin: 0 auto;
    ul{
      padding-left: 20px;
      padding-top: 16px;
      li{
        margin-bottom: 24px;
      }
    }
  }
  .sticky {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 24px 0;
    background:rgba($ivory, .85);
    box-shadow: 0 -1px 4px rgba($black, .25);
    border-top: 1px solid $gold;
    backdrop-filter: blur(8px);

    &.hidden{
      display: none;
    }
  }

  .bullets{
    justify-content: space-between;
  }

  /** Ipad */
  @media only screen and (min-width: 1024px) and (max-width: 1156px) {
    .the-space-row{
      .image-content{
        padding-right: 50px !important;
      }
      h1{
        font-size: 35px !important;
      }
      p{
        font-size: 16px !important;
      }
    }

    .line-separated-list{
      li{
        font-size: 16px !important;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
  /** Mobile */
  @media only screen and (max-width: 1023px) {
    .video-title {
      padding-top: 24px;
    }
    .the-space-row{
      padding: 0px 0 40px 0;
      .image-content{
        padding-bottom: 50px;
      }
    }

    .technology-section{
      padding:0 !important;
      .image-content{
        padding-bottom: 50px;
      }
    }
    .downloads-section{
      padding-bottom: 50px;
    }

    .experiences-section{
      padding: 40px 0 !important;
      .header {
        margin: 0;
      }
    }
    .row.header{
      width: 100% !important;
      h1{
        font-size: 35px !important;
      }
      p{
        font-size: 16px !important;
      }
    }

    .row.app-label{
      margin: 0 !important;
    }
    .image-container{
      position: static !important;
    }
    img.app-preview-image{
      margin: auto;
      width: 100%;
      max-width: 608px;
      position: static !important;

    }
    .fabric-bg.overflow-horizontal{
      .text-content{
        width: 100% !important;
      }
      .Aligner-item{
        margin: 0 auto;
      }
    }

    .tiers-carousel{
      .text-content{
        width: 100% !important;
        .generic-link{
          text-align: center;
        }
      }
    }

    .gallery-type-section{

      h1{
        font-size: 28px !important;
      }
      p{
        margin: 0 20%;
      }
      img{
        padding-top: 20px;
      }
      .extra-vertical-margin{
        margin: 40px 0;
        &.bottom{
          margin-bottom: 0;
        }
      }
    }
    /** End mobile */
  }
}


#rfp-modal{
  .modal-dialog{
    max-width: 1140px;
  }
  .contact-details{
    h1{
      font-size: 2.5rem;
      line-height: 2.625rem;
    }
    h2.instr{
      font-size: 16px;
      margin-bottom: 32px;
    }
    p.details{
      font-size: 20px;
      margin-bottom: 32px;
    }
    p.follow{
      font-size: 24px;
    }

    .sns-icons{
      display: inline-block;
      margin: 0 auto;
      .sns-logo + .sns-logo{
          margin-left: 18px;
      }
    }
  }
  .rfp-modal{
    position: relative;
    .close-button{
      position: absolute;
      top: -12%;
      right: -7%;
    }
    padding: 30px;
    background-color: $ivory;
    border-radius: 0;
    border-width: 0;
    .modal-header{
      display: none;
    }
    .modal-body{
      padding: 0;
      color: $palm-green;
      .image-content{
        .VueCarousel{
          height: 100%;

          img{
            object-fit: cover;
            width: 100%;
          }
        }
      }
      .text-content{
        padding: 0 8%;
        p.desc{
          font-size: 21px;
        }
        p.email{
          font-size: 16px;
          text-transform: uppercase;
          margin-bottom: 0;
        }
      }
    }
    .sns-logo-containers{
      margin-top: 15px;
    }
    .modal-footer{
      display: none;
    }
    /** Mobile */
    @media only screen and (max-width: 1248px) {
      .close-button{
        z-index: 999;
        top: 0 !important;
        right: 0 !important;
      }
    }
  }
  /** Mobile */
  @media only screen and (max-width: 1023px) {
    .modal-body{
      .text-content{
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        h1{
          font-size: 30px !important;
          margin-bottom: 10px !important;
        }
        p.desc{
          font-size: 16px !important;
        }
        p.email{
          font-size: 16px !important;
        }
      }
    }
    /** End of Mobile */
  }
}

#welcome-modal{

  .sig {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 2em;

    img {
      max-width: 200px;
    }
  }
  .modal-dialog{
    max-width: 700px;
  }
  .contact-details{
    border: solid 2px $gold;
    padding: 2em;
    h1{
      font-size: 2.5rem;
      line-height: 2.625rem;
      padding-bottom: .5em;
    }
    h2.instr{
      font-size: 16px;
      margin-bottom: 32px;
    }
    p.details{
      font-size: 16px;
      margin-bottom: 32px;
      text-align: center;
    }
    p.follow{
      font-size: 24px;
    }
  }
  .welcome-modal{
    position: relative;
    .close-button{
      position: absolute;
      top: -12%;
      right: -7%;
    }
    padding: 30px;
    background-color: $ivory;
    border-radius: 0;
    border-width: 0;
    .modal-header{
      display: none;
    }
    .modal-body{
      padding: 0;
      color: $palm-green;
      .image-content{
        .VueCarousel{
          height: 100%;

          img{
            object-fit: cover;
            width: 100%;
          }
        }
      }
      .text-content{
        padding: 0 8%;
        p.desc{
          font-size: 21px;
        }
        p.email{
          font-size: 16px;
          text-transform: uppercase;
          margin-bottom: 0;
        }
      }
    }
    .modal-footer{
      display: none;
    }
    /** Mobile */
    @media only screen and (max-width: 1248px) {
      .close-button{
        z-index: 999;
        top: 0 !important;
        right: 0 !important;
      }
    }
  }
  /** Mobile */
  @media only screen and (max-width: 1023px) {
    .modal-body{
      .text-content{
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        h1{
          font-size: 30px !important;
          margin-bottom: 10px !important;
        }
        p.desc{
          font-size: 16px !important;
        }
        p.email{
          font-size: 16px !important;
        }
      }
    }
    /** End of Mobile */
  }
}

.rfp-form{
  h6{
    font-size: 16px;
    font-weight: 600;
  }
  .form-group{
    display: flex;
    margin-bottom: 0;

    input + input{
      margin-left: 20px;
    }
  }
  .checkbox-container{
    margin-top: 33px;
    input{
      display:inline-block;
      width: auto;
      margin-right: 10px;
    }
    label{
      display: inline-block;
      color: $palm-green;
      a{
        color: $palm-green;
        text-decoration: underline;
      }
    }
  }
  input, textarea, select{
    display: block;
    width: 100%;
    font-size: 16px;
    justify-content: space-between;
  }

  input{
    background: transparent;
    border: 0;
    border-bottom: 1px solid $palm-green;
    margin-bottom: 33px;
    outline: none;

    &::placeholder{
      color: $palm-green;
    }
  }

  .select{
    position: relative;
    img{
      position: absolute;
      right: 0;
      bottom: 30%;
      height: 9.5px;
      width: 18px;
      object-fit: cover;
    }
  }
  select{
    background: transparent;
    border: 0px transparent solid;
    border-bottom: 1px solid $palm-green;
    margin-bottom: 33px;
    outline: none;
    color: $palm-green;

    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
  }

  textarea{
    background: transparent;
    padding: 20px;
    border-bottom: 1px solid $palm-green;
  }

  input:focus, textarea:focus, button:focus, select:focus{
    outline: none;
  }

  button{
    display: block;
    background: transparent;
    border: 0;
    border-bottom: 2px solid #EEC6BB;
    font-size: 18px;
    color: $palm-green;
    letter-spacing: 0.2em;
    margin-top: 20px;
  }
}

.map-spacing{
  margin-top: 140px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1470px;
}

.map-background{
  background-color: #FFF;
}
@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .map-spacing{
  margin-top: 140px;
  margin-left: 0% !important;
  margin-right: 0% !important;
  }
  .map-fontstyle{
  font-size: 16px !important;
  }
  .map-image1{
  object-fit:contain;
  height: 20px;
  width: auto;
  }
  .map-image2{
  object-fit:contain;
  height: 20px;
  width: auto;
  }
}
// Mobile
@media only screen and (max-width: 1023px){
  @media only screen and (min-width: 768px) and (max-width: 912px){
    .map-fontstyle{
    font-size: 21px !important;
    }
    .top-spacing{
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    }
  }
  @media only screen and (max-width: 767px){
    .map-spacing{
    display: block !important;
    margin-top: 140px;
    margin-left: 0% !important;
    margin-right: 0% !important;
    }
    .map-fontstyle{
    font-size: 16px !important;
    }
    .map-image1{
    object-fit:contain;
    height: 20px;
    width: auto;
    }
    .map-image2{
    object-fit:contain;
    height: 20px;
    width: auto;
    }
    .top-spacing{
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    }
  }
}
.top-spacing{
  margin-top: 50px;
  margin-bottom: 50px;
}
.map-fontstyle{
  font-size: 21px;
  color: #2A4735 !important;
}
.fit-image{
height: 100%;
width: 100%;
object-fit: cover;
}

</style>

<script>
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

// import axios from 'axios'
import {RSVPForm} from "@/models/RSVPForm";
import {API} from "@/API";

import VueRecaptcha from 'vue-recaptcha';

var recaptchaMessage = "Please complete the reCAPTCHA verification to proceed.";

export default {
  name: 'MeetingsEventsSpaceBody',
  data() {
    return{
      isLoading: false,
      fullPage: true,
      currentTierPage: 0,
      floatingDownloadsVisible: false,
      form: RSVPForm.blank('605'),
      bookingForm: {
        recaptchaVerified: false,
        pleaseTickRecaptchaMessage: '',
        siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY
      }
    }
  },
  components: {
    Loading,
    VueRecaptcha
  },
  created () {
    window.addEventListener('scroll', this.meetingsDidScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.meetingsDidScroll);
  },
  mounted() {
    this.showModalWelcome()
  },
  methods: {
    goToTierPage(pageIdx){
      this.currentTierPage = pageIdx
    },
    handlePageChange(page){
      this.currentTierPage = page
    },
    meetingsDidScroll(){
      // console.log(e.target)
      var diff = (this.$refs['contact-section'].getBoundingClientRect().bottom - window.innerHeight)
      this.floatingDownloadsVisible = window.scrollY > 100 && diff > -150
    },
    closeModal(){
      this.$bvModal.hide('rfp-modal')
      this.currentMemberIdx = 0
    },
    showModalWelcome(){
     this.$bvModal.show('welcome-modal')
    },
    closeModalWelcome(){
      this.$bvModal.hide('welcome-modal')
      this.currentMemberIdx = 0
    },
    onVerify() {
      this.bookingForm.pleaseTickRecaptchaMessage = '';
      this.bookingForm.recaptchaVerified = true;
    },

    onExpired() {
      this.bookingForm.pleaseTickRecaptchaMessage = recaptchaMessage;
      this.bookingForm.recaptchaVerified = false;
    },

    checkForm(event){
      if (!this.bookingForm.recaptchaVerified) {
        this.bookingForm.pleaseTickRecaptchaMessage = recaptchaMessage;
        return true; // prevent form from submitting
      }
      var $this = this
      event.preventDefault()

      if($this.form.agreement == false){
          Swal.fire(
            'Oops...',
            'You must accept the terms of agreement.',
            'info'
          )
        return;
      }

      $this.isLoading = true;
      API.submitRSVP(this.form)
        .then(function(/* response */){
          $this.isLoading = false
          // console.log(response)

          $this.form = RSVPForm.blank('605')

          Swal.fire(
            'Thank You!',
            'Your inquiry has been sent.',
            'success'
          )
        })
        .catch(function (/* error */) {
          $this.isLoading = false
          // console.log(error)
          // handle error
          Swal.fire(
            'Oops...',
            'Something went wrong!',
            'error'
          )
        })

    },
  }
}
</script>
