<template>
    <div id="navigation-bar" v-resize="onResize">
        <!-- All pages except contact -->
        <nav :class="{'navbar': true,
                        'navbar-light': true,
                        'navbar-template': true,
                        'pulled-logo-nav': true}"
                v-if="!willShowSideMenu() && navType(currentRouteName) == 'RegularNav'">
            <div class="navbar-wrapper" :class="{'darken-element': isDarkNav(currentRouteName)}">
                <div class="nav-links">
                    <router-link to="/" class="txt-link left-link">Ease</router-link>
                    <router-link to="/locations" class="txt-link left-link">Meeting &amp; Event Spaces</router-link>
<!--                     <router-link to="/virtual" class="txt-link">Hybrid &amp; Virtual Services</router-link> -->

                    <!-- <a class="txt-link left-link parent-link"
                        @mouseover="enteredParent"
                        @mouseleave="leftParent"
                        :class="{'router-link-exact-active router-link-active': isHybridMeetingPage()}">
                        Events &amp; Hybrid Meetings
                        <ul class="submenu"
                            @mouseover="submenu_hovered = true"
                            @mouseleave="submenu_hovered = false"
                            :class="{
                                'hidden-menu': !(this.parent_hovered || this.submenu_hovered)
                            }">
                            <li>
                                <router-link to="/in-person-spaces" class="txt-link">In-person Spaces</router-link>
                            </li>
                            <li>
                                <router-link to="/hybrid-and-virtual" class="txt-link">Hybrid &amp; Virtual</router-link>
                            </li>
                        </ul>
                    </a> -->
                    <!-- <router-link to="/meetings-and-events-spaces" class="txt-link left-link">Hybrid Meetings &amp; Events</router-link> -->
                    <router-link to="/luxury-amenities" class="txt-link right-link">Luxury Amenities</router-link>
                    <router-link to="/contact" class="txt-link right-link">Inquire</router-link>

                    <b-link v-b-toggle.sidebar-backdrop>
                        <img src="@/assets/hamburger.svg" alt="Navigation Menu"/>
                    </b-link>
                </div>
                <!-- <div class="side-menu-wrapper">

                </div> -->
            </div>
        </nav>
        <!-- Contact Nav/Mobile Nav -->
        <nav class="navbar navbar-light navbar-template"
        :class="{'contact-nav': navType(currentRouteName) == 'ContactNav'}"
            v-if="willShowSideMenu() || navType(currentRouteName) == 'ContactNav'">
            <div class="navbar-wrapper" :class="{'darken-element': navType(currentRouteName) == 'ContactNav'}">
                <router-link to="/" class="navbar-brand-link" v-if="navType(currentRouteName) == 'ContactNav'"><img class="ease-logo" src="@/assets/ease-hospitality-logo.svg" alt="Ease Hospitality"/></router-link>
                <div class="nav-links side-links left-side">
                    <!-- <router-link to="/" class="txt-link left-link">@Ease</router-link> -->
                    <router-link to="/" class="txt-link left-link">Ease</router-link>
                    <router-link to="/locations"
                                class="txt-link left-link">Meeting &amp; Event Spaces</router-link>
                    <!-- <a class="txt-link left-link parent-link"
                        @mouseover="enteredParent"
                        @mouseleave="leftParent"
                        :class="{'router-link-exact-active router-link-active': isHybridMeetingPage()}">
                        Hybrid Meetings &amp; Events
                        <ul class="submenu"
                            @mouseover="submenu_hovered = true"
                            @mouseleave="submenu_hovered = false"
                            :class="{
                                'hidden-menu': !(this.parent_hovered || this.submenu_hovered)
                            }">
                            <li>
                                <router-link to="/in-person-spaces" class="txt-link">In-person Spaces</router-link>
                            </li>
                            <li>
                                <router-link to="/hybrid-and-virtual" class="txt-link">Hybrid &amp; Virtual</router-link>
                            </li>
                        </ul>
                    </a> -->
                </div>
                <div class="nav-links side-links right-side">
<!--                     <router-link to="/virtual" class="txt-link">Hybrid &amp; Virtual Services</router-link>   -->
                    <router-link to="/luxury-amenities" class="txt-link right-link">Luxury Amenities</router-link>
                <router-link to="/contact" class="txt-link left-link">Inquire</router-link>
                </div>

                <div class="side-menu-wrapper">
                    <b-link v-b-toggle.sidebar-backdrop>
                        <img class="centering" alt="Navigation Menu" src="@/assets/hamburger.svg" />
                    </b-link>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
    export default {
        name: "NavigationBar",
        computed: {
            currentRouteName() {
                return this.$route.name;
            }
        },
        data() {
            return {
                parent_hovered: false,
                submenu_hovered: false,
                existingTimer: null,
            }
        },
        methods:{
            enteredParent(){
                if(this.existingTimer != null){
                    clearInterval(this.existingTimer)
                }
                this.parent_hovered = true
            },
            leftParent(){
                this.existingTimer = setInterval(() => {
                    if(this.existingTimer != null){
                        clearInterval(this.existingTimer)
                    }
                    this.parent_hovered = false
                },250);
            },
            isHybridMeetingPage(){
                return this.currentRouteName == "In-Person Spaces" ||
                        this.currentRouteName == "Hybrid & Virtual"
            }
        }
    }
</script>

<style lang="scss">
@import '@/styles/navigation.scss';
</style>