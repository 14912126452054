<template>
  <div class="location-605">
    <L605Banner />
    <L605Body />
  </div>
</template>

<script>
import L605Banner from "@/components/locations/605/Banner.vue";
import L605Body from "@/components/locations/605/Body.vue";

export default {
  name: 'Location605',
  components: {
    L605Banner
    ,
    L605Body
  }
}
</script>
