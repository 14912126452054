<template>
    <b-sidebar
        id="sidebar-backdrop"
        :backdrop-variant="`variant`"
        bg-variant="dark"
        backdrop
        right
        shadow
        class="overridden-green full"
    >
        <div class="px-3 py-2">
        <b-nav vertical>
            <b-nav-item to="/"><img class="ease-logo-sidebar" src="@/assets/logo-ease-sidenav.svg" alt="Ease Hospitality"/></b-nav-item>
            <img class="close-button" src="@/assets/close.svg" alt="close menu" v-b-toggle.sidebar-backdrop />

            <div class="links">
                <b-nav-item to="/">Ease</b-nav-item>
                <b-nav-item to="/locations">Meeting &amp; Event Spaces</b-nav-item>
<!--                 <b-nav-item to="/virtual"> Hybrid &amp; Virtual Services</b-nav-item> -->
                <b-nav-item to="/luxury-amenities">Luxury Amenities</b-nav-item>
                <li class="divider"></li>
                <b-nav-item to="/about-us" class = "links-sidebar-small">about us</b-nav-item>
                <b-nav-item to="/press" class= "links-sidebar-small">press</b-nav-item>
<!--                 <b-nav-item to="/careers" class= "links-sidebar-small">careers</b-nav-item> -->
                <b-nav-item to="/contact" class= "links-sidebar-small">contact</b-nav-item>
            </div>
            <div class="sns-logo-containers">
                <a href="https://www.facebook.com/Ease-Hospitality-106482987830969" target="_blank" class="sns-logo"><img src="@/assets/footer/footer-facebook-icon.svg" alt="Facebook" /></a>
                <a href="https://www.instagram.com/ease.hospitality" target="_blank" class="sns-logo"><img src="@/assets/footer/footer-instagram-icon.svg" alt="Instagram" /></a>
                <a href="https://www.linkedin.com/company/easehospitality/" target="_blank" class="sns-logo"><img src="@/assets/footer/footer-linkedin-icon.svg" alt="LinkedIn" /></a>
            </div>
        </b-nav>
        </div>
    </b-sidebar>
</template>
<script>
    export default {
        name: "SideMenuOverlay",
        computed: {
            currentRouteName() {
                return this.$route.name;
            }
        },
        data() {
            return {
                submenu_opened: false,
            }
        },
        methods:{
            enteredParent(){
                if(this.existingTimer != null){
                    clearInterval(this.existingTimer)
                }
                this.parent_hovered = true
            },
            leftParent(){
                this.existingTimer = setInterval(() => {
                    if(this.existingTimer != null){
                        clearInterval(this.existingTimer)
                    }
                    this.parent_hovered = false
                },250);
            },
            isHybridMeetingPage(){
                return this.currentRouteName == "In-Person Spaces" ||
                        this.currentRouteName == "Hybrid & Virtual"
            },
            toggleParentLink(){
                this.submenu_opened = !this.submenu_opened
            }
        }
    }
</script>

<style lang="scss">
@import '@/styles/navigation.scss';
.ease-logo-sidebar{
    margin-top: 1.75rem;
    width: 100%;
    height: 100%;
    margin-left: 0.5rem;
}
.close-button{
    position: absolute;
    top: 6%;
    right: 8%;
    cursor: pointer;
}
.links{
    text-align: right;
    font-size: 16px;
}
.links-sidebar-small{
    text-align: right;
    padding: 2px !important;
    font-size: 12px
}
.divider {
  border-top: 2px solid #3F3F3F;
  margin-top: 40px !important;
  margin-bottom: 20px !important;
  width: 91% !important;
  padding-top: 5px;
  margin: auto;
}
ul.nav{
    z-index: 1000;
    text-align: left;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 20px;
    li.nav-item{
        list-style-type: none;
        padding: 10px;
        margin-left: auto;
        margin-right: auto;
        a{
            color: $light-text-color;
            &.active{
                color: #C28A44 !important;
            }
        }
    }
}

.b-sidebar{
    min-height: 800px;
    min-width: 200px;
}
.b-sidebar-header{
    .close{
        opacity: 0;
    }
}
.sns-logo-containers{
    display: inline-block;
    margin: auto;
    margin-bottom: 40px;
    .sns-logo + .sns-logo{
        margin-left: 18px;
    }
}
.overridden-green{
    .b-sidebar{
        background-color: $black !important;
        width: 100%;
    }
}

/** Desktop and above */
@media only screen and (min-width: 1023px){
    .overridden-green{
        .b-sidebar{
            width: 25%;
        }
    }
}

@media only screen and (min-width: 1023px) and (max-width: 1199px) {
    .links{
        text-align: right;
        font-size: 16px;
    }
    .links-sidebar-small{
        text-align: right;
        padding: 2px !important;
        font-size: 12px !important;
    }
}

@media only screen and (max-width: 1023px){
    .links{
        text-align: center;
        font-size: 20px;
    }
    .links-sidebar-small{
        text-align: center;
        padding: 2px !important;
        font-size: 16px !important;
    }
}
//For 1366x768
@media only screen and (min-width: 1200px) and (max-width: 1599px){
    .ease-logo-sidebar{
        margin-top: 1.75rem;
        width: 90%;
        height: 90%;
        margin-left: 0.5rem;
    }
    .divider {
        border-top: 2px solid #3F3F3F;
        margin-top: 20px !important;
        width: 91% !important;
        margin: auto;
    }
    .links{
        text-align: right;
        font-size: 16px;
    }
    .links-sidebar-small{
        text-align: right;
        padding: 2px !important;
        font-size: 14px !important;
    }
}
//For Desktops Larger than 1600px
@media only screen and (min-width: 1600px) {
    .links{
    text-align: right;
    font-size: 28px;
    }
    .links-sidebar-small{
        text-align: right;
        padding: 2px !important;
        font-size: 20px
    }
}
//iPhone SE
@media only screen and (max-height: 668px){
    .ease-logo-sidebar{
    margin-top: 2.5rem;
    width: 60%;
    height: 60%;
    margin-left: 2.5rem;
    margin-bottom: 0.5rem;
    }
    .links .nav-link{
        text-align: center;
        font-size: 16px !important;
    }
    ul.nav{
        padding: 2px !important;
        li.nav-item{
            padding: 2px !important;
        }
    }
    .links-sidebar-small{
        text-align: right;
        padding: 2px !important;
        font-size: 12px !important;
    }
    .sns-logo-containers{
    display: inline-block;
    margin: auto;
    margin-top: 30px;
        .sns-logo + .sns-logo{
        margin-left: 18px;
        }
    }
    @media only screen and (max-height: 668px) and (min-width: 1023px){
        .links .nav-link{
          text-align: right !important;
          font-size: 14px !important;
        }
    }
    .divider {
        border-top: 2px solid #3F3F3F;
        margin-top: 20px !important;
        width: 91% !important;
        margin: auto;
    }
}
//iPhoneXR
@media only screen and (min-height: 669px) and (max-height: 916px) and (max-width: 415px){
    .ease-logo-sidebar{
    margin-top: 2.5rem;
    width: 70%;
    height: 70%;
    margin-left: 2.25rem;
    margin-bottom: 0.5rem;
    }
    .links .nav-link{
        text-align: center;
        font-size: 20px !important;
    }
    ul.nav{
        padding: 6px !important;
        li.nav-item{
            padding: 6px !important;
        }
    }
    .links-sidebar-small{
        text-align: right;
        padding: 6px !important;
        font-size: 16px !important;
    }
    .sns-logo-containers{
    display: inline-block;
    margin: auto;
    margin-bottom: 40px;
        .sns-logo + .sns-logo{
        margin-left: 18px;
        }
    }
    .divider {
        border-top: 2px solid #3F3F3F;
        margin-top: 20px !important;

        width: 91% !important;
        margin: auto;
    }
}
//Samsung S8
@media only screen and (min-height: 669px) and (max-height: 916px) and (max-width: 361px){
    .divider {
        border-top: 2px solid #3F3F3F;
        margin-top: 20px !important;
        margin-bottom: 20px !important;
        width: 91% !important;
        margin: auto;
    }
    .links .nav-link{
        text-align: center;
        font-size: 16px !important;
    }
    ul.nav{
        padding: 5px !important;
        li.nav-item{
            padding: 5px !important;
        }
    }
    .links-sidebar-small{
        text-align: right;
        padding: 5px !important;
        font-size: 14px !important;
    }
    .sns-logo-containers{
    display: inline-block;
    margin: auto;
    margin-top: 30px;
        .sns-logo + .sns-logo{
        margin-left: 18px;
        }
    }
}
//iPad Air & Mini
@media only screen and (min-height: 920px) and (max-height: 1180px) and (max-width: 821px){
    .links{
        text-align: center;
        font-size: 32px;
    }
    ul.nav{
        padding: 10px !important;
        li.nav-item{
            padding: 10px !important;
        }
    }
    .links-sidebar-small{
        text-align: center;
        padding: 2px !important;
        font-size: 28px !important;
    }
    .sns-logo-containers{
    display: inline-block;
    margin: auto;
    margin-bottom: 60px;
        .sns-logo + .sns-logo{
        margin-left: 18px;
        }
    }
}

//iPad Air & Mini
@media only screen and (min-height: 920px) and (max-height: 1024px) and (max-width: 769px){
    .links{
        text-align: center;
        font-size: 24px;
    }
    ul.nav{
        padding: 10px !important;
        li.nav-item{
            padding: 10px !important;
        }
    }
    .links-sidebar-small{
        text-align: center;
        padding: 2px !important;
        font-size: 20px !important;
    }
    .sns-logo-containers{
    display: inline-block;
    margin: auto;
    margin-bottom: 60px;
        .sns-logo + .sns-logo{
        margin-left: 18px;
        }
    }
}




//Surface Duo
@media only screen  and (min-height:719px) and (max-height: 721px) and (max-width: 721px){
    .ease-logo-sidebar{
    margin-top: 2.5rem;
    width: 60%;
    height: 60%;
    margin-left: 2.5rem;
    margin-bottom: 0.5rem;
    }
    .links{
        text-align: center;
        font-size: 16px !important;
    }
    ul.nav{
        padding: 5px !important;
        li.nav-item{
            padding: 5px !important;
        }
    }
    .links-sidebar-small{
        text-align: center;
        padding: 5px !important;
        font-size: 14px !important;
    }
    .sns-logo-containers{
    display: inline-block;
    margin: auto;
    margin-top: 30px;
        .sns-logo + .sns-logo{
        margin-left: 18px;
        }
    }
    .divider {
        border-top: 2px solid #3F3F3F;
        margin-top: 20px !important;
        width: 91% !important;
        margin: auto;
    }
}

//Surface Pro 7
@media only screen and (min-height: 1300px) and (max-height: 1370px) and (max-width: 913px){
    .ease-logo-sidebar{
    margin-top: 2.5rem;
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;
    }
    .links .nav-link{
        text-align: center;
        font-size: 40px !important;
    }
    ul.nav{
        padding: 10px !important;
        li.nav-item{
            padding: 10px !important;
        }
    }
    .links-sidebar-small{
        text-align: right;
        padding: 2px !important;
        font-size: 24px !important;
    }
    .sns-logo-containers{
    display: inline-block;
    margin: auto;
    margin-bottom: 60px;
        .sns-logo + .sns-logo{
        margin-left: 18px;
        }
    }
}
</style>