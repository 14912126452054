<template>
  <div class="news-press-page">
    <NewsPressBanner />
    <Body />
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import NewsPressBanner from "@/components/news-press/Banner.vue";
import Body from "@/components/news-press/Body.vue";

export default {
  name: 'NewsPress',
  components: {
    NewsPressBanner,
    Body,
  }
}
</script>