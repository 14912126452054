<template>
  <div class="locations" v-resize="onResize">
    <NavigationBar />
    <div class="slider-container paginated">
      <carousel
        v-if="!isMobile()"
        :per-page=1
        :paginationEnabled=false
        :value=currentDesktopPageIdx>

        <!-- Location Set 1 Slide Sample -->
        <slide v-for="(group, groupIdx) in getDesktopSlider()" :key="`group-${groupIdx}`">
          <div class="row slide">
            <div :class="`${group.colsize} desktop location-col`"
                  v-for="(location, locIdx) in group.locations" :key="`location-${groupIdx}-${locIdx}`">
              <router-link :to="location.link">
                <img :src="location.image" class="location-content" alt="Location"/>
                <div class="dark-overlay">
                  <img :src="location.logo" class="location-logo desktop" alt="Location Logo"/>
                  <div class="location-text">
                    <h1>{{location.name}}</h1>
                    <h6>{{location.city}}</h6>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </slide>

        <!-- End of Carousel -->
      </carousel>

      <carousel
        ref="carousel"
        v-if="isMobile()"
        :class="{ 'carousel-hidden': isCarouselHidden }"
        :per-page=1
        :paginationEnabled=false
        :value=currentMobilePageIdx>
        <!-- Location 1 Slide Sample -->
        <slide v-for="(location, locIdx) in this.locations" :key="`location-${locIdx}`">
          <div class="row slide">
            <div class="col-md-12 location-col">
              <router-link :to="location.link">
                <img :src="location.image" class="location-content" alt="Location" />
                <div class="dark-overlay">
                  <img :src="location.logo" class="location-logo mobile" alt="Location Logo"/>
                  <div class="location-text">
                    <h1>{{location.name}}</h1>
                    <h4>{{location.city}}</h4>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </slide>

        <!-- End of Carousel -->
      </carousel>

      <div class="paginator-arrows">
        <img :class="{'d-none': !isLeftPaginatorVisible()}" @click="previousPage" src="@/assets/home-mobile/left-arrow-mobile.svg" alt="Previous"/>
        <img :class="{'d-none': !isRightPaginatorVisible()}" @click="nextPage" src="@/assets/home-mobile/right-arrow-mobile.svg" alt="Next"/>
      </div>
    </div>
  </div>
</template>

<style lang="scss">

.carousel-hidden {
  opacity: 0;
  pointer-events: none;
}

.locations{
  .slider-container{
    padding-top: 25px;
    text-align: center;
  }
  .location-col{
    max-height: 80vh;
    &.desktop{
      padding: 0;
    }
    &.mobile{
      .row.slide{
        margin-left: 0;
        margin-right: 0;
      }
    }
    position: relative;
    .dark-overlay{
      position: absolute; /* Sit on top of the page content */
      width: 100%; /* Full width (cover the whole page) */
      height: 100%; /* Full height (cover the whole page) */
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0,0.7); /* Black background with opacity */
      z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
      cursor: pointer; /* Add a pointer on hover */

      .location-text{
        color: white;
        position: absolute;
        bottom: 15%;
        width: 100%;
      }
    }
  }
  .location-content{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


  .paginated{
    .paginator-arrows{
      img{
        &:first-child{
          left: 2%;
        }
        &:last-child{
          right: 2%;
        }
      }
    }
  }

  .location-logo{
    height: 95%;
    &.desktop{
      width: 50%;
    }
    &.mobile{
      width: 65%;
    }
  }
}
</style>

<script>
import NavigationBar from '@/components/NavigationBar.vue'

export default {
  name: 'Locations',
  data() {
    return{
      locations: [
        // Location 1
        {
          name: "605 3rd Ave",
          city: "New York, NY",
          link: "/605",
          image: require(`@/assets/locations/location-2.png`),
          logo: require(`@/assets/ease-605-logo.svg`)
        },
        // Location 2
        {
          name: "1345 AOA",
          city: "New York, NY",
          link: "/1345",
          image: require(`@/assets/locations/location-1.png`),
          logo: require(`@/assets/ease-meetings-logo.svg`)
        },
        // Location 3
        // {
        //   name: "Virtual",
        //   city: "Anywhere",
        //   link: "/virtual",
        //   image: require(`@/assets/locations/location-3.png`)
        // },
      ],
      currentDesktopPageIdx: 0,
      currentMobilePageIdx: 0,
      isCarouselHidden: true,
    }
  },
  methods: {
    getDesktopSlider(){
      // gets the number of groups based on the listed location
      var groupsCount = Math.ceil(this.locations.length/4)

      //initializes slider groups array
      var sliderGroups = new Array()
      for(var i = 0; i < groupsCount; i++){
        // initializes group locations
        var groupLocations = new Array()
        var locationsPerPage = 2
        //will add to group based on locations base idx
        var base = i * locationsPerPage

        for(var idx = base; idx < base + locationsPerPage; idx++){
          if(idx < this.locations.length){
            // will add
            groupLocations.push(this.locations[idx])
          }
        }

        var group = {
          group: `Group ${i + 1}`,
          colsize: `col-md-${12 / locationsPerPage}`,
          locations: groupLocations
        }
        sliderGroups.push(group)
        // adds the new slider group
        // console.log(`Added group: ${sliderGroups[i].group}`)
        // console.log(`Col size: ${sliderGroups[i].colsize}`)
        // console.log(`Locations: ${sliderGroups[i].locations.length}`)
      }

      return sliderGroups
    },
    previousPage(){
      if(!this.isMobile()){
        // desktop
        this.currentDesktopPageIdx = Math.max(this.currentDesktopPageIdx - 1, 0)
      }else{
        // mobile
        this.currentMobilePageIdx =  Math.max(this.currentMobilePageIdx - 1, 0)
      }
    },
    nextPage(){
      if(!this.isMobile()){
        // desktop
        this.currentDesktopPageIdx = Math.min(this.currentDesktopPageIdx + 1, this.getDesktopSlider().length - 1)
      }else{
        // mobile
        this.currentMobilePageIdx = Math.min(this.currentMobilePageIdx + 1, this.locations.length - 1)
      }
    },
    isLeftPaginatorVisible(){
      if(!this.isMobile()){
        // desktop
        return this.currentDesktopPageIdx > 0
      }else{
        // mobile
        return this.currentMobilePageIdx > 0
      }
    },
    isRightPaginatorVisible(){
      if(!this.isMobile()){
        // desktop
        return this.currentDesktopPageIdx < this.getDesktopSlider().length - 1
      }else{
        // mobile
        return this.currentMobilePageIdx < this.locations.length - 1
      }
    }
  },
  components: {
    NavigationBar,
  },
  mounted() {
    if(this.isMobile()){
      this.currentMobilePageIdx = 1;
      setTimeout(() => {
        this.currentMobilePageIdx = 0;
        this.isCarouselHidden = false;
      }, 200);
    }else {
      this.isCarouselHidden = false;
    }  
  }
}
</script>
